
import React, {useEffect, useState , useRef }from "react";

export function ExcludeForecast(props)  {
//   constructor(props) {
//     //super(props);
//     this.btnClickedHandler = this.btnClickedHandler.bind(this);
//   }
 const btnClickedHandler=()=>{
     console.log(props);
    props.clicked([props.rowIndex,props.value,props.data.num_line]);
  };

    return (
      <button onClick={btnClickedHandler}>Masquer</button>
    )

}


// import React from 'react';

// export default (props) => {
//   const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

//   const buttonClicked = () => {
//     alert(`${cellValue} medals won!`);
//   };

//   return (
//     <span>
//       <span>{cellValue}</span>&nbsp;
//       <button onClick={() => buttonClicked()}>Push For Total</button>
//     </span>
//   );
// };
