import { useState, useEffect } from 'react';

export default function useCategory(selected) {
  const [category, setCategory] = useState(null);

  useEffect(() => {
      if(selected !== null)setCategory=selected;

  });

  return category;
}