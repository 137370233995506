
import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import { Category } from "./Category";
import { 
     Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';

function CategoryModal(props) {

const [scrollBehavior, setScrollBehavior] = React.useState('inside');
const { isOpen , onOpen, onClose } = useDisclosure();

 //const [checkedItems,setCheckedItems] = useState([]);
// const [options, setOptions] = useState();
const categoryNameChange= (e)=>{
  //console.log("name" + e.target.value);
  //props.categoryName=e.target.value;()
  console.log(e.target.value);
 // console.log(checkedItems);
  props.onChange(e.target.value);
  //setCategoryName(e.target.value);
}

const categorySend =()=>{
  props.onCategorySend(category);
}

const handleChange=(e)=> {
    //const value = e.target.value.split("_");
    const value=e.target.value;
    const isChecked = e.target.checked;

  props.onEntriesCategorized(value,isChecked);
  };

const [category, setCategory] = useState(1);
//const [selectedCategory,setSelectedCategory]=useState(1);
let selectedCategory;

// if (typeof props.params.data !== "undefined")
//   selectedCategory = {
//     value: "/api/forecast_categories/" + props.params.data.category_id,
//     label: props.params.data.category,
//   };

const onCategoryAdd = (value) => {
  console.log(value.value+'Boom ' + value.label);
  props.addNewCategory(value.value,value.label,props.categoryType);
  setCategory(value.value);
  props.categoryNameChange(value.label);
};

const onCategoryChange = (value) => {
  console.log(value);
  setCategory(value.value);
  props.categoryNameChange(value.label);
};

    let options=null;
    let expenseVisible=0;
    let revenueVisible=1;
   if(props.categoryType=='1') {
    expenseVisible=1;
    revenueVisible=0;
    options = props.expenseCategories;
    //setOptions();
   }
   else{
    options = props.revenueCategories;
  //  setOptions();
   }



 
return (
  <Modal
    onClose={props.onClose}
    isOpen={props.isOpen}
    isCentered
    size="xl"
    scrollBehavior={scrollBehavior}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Catégoriser</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack direction="row" spacing="24px">
          <FormControl>
            {/* <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                      Nom
                    </FormLabel>
                    <Input fontSize="xs"  placeholder="" defaultValue={props.categoryName}  onChange={(e)=>categoryNameChange(e)}   />
           */}
            <Category
              options={options}
              selectedCategory={props.selectedCategory}
              onCategoryChange={onCategoryChange}
              categoryType={props.categoryType}
              onCategoryAdd={onCategoryAdd}
              company={props.company}
            ></Category>
          </FormControl>
        </Stack>

        {/* {props.expenseToCategorize.length} */}

        <Stack direction="row" spacing="24px">
          <CheckboxGroup colorScheme="green" defaultValue={[]}>
            <Stack
              spacing={[1, 5]}
              direction="column"
              style={{ display: expenseVisible ? "" : "none" ,minHeight:'100px'}}
            >
              {props.expenseToCategorize.map((value, index) => {
                if (index % 2 == 0)
                  //if (index < props.expenseToCategorize.length / 2)
                  return (
                    <Checkbox value={value[1]} onChange={handleChange}>
                      {value[0]}
                    </Checkbox>
                  );
              })}
            </Stack>
            <Stack
              spacing={[1, 5]}
              direction="column"
              style={{ display: expenseVisible ? "" : "none" }}
            >
              {props.expenseToCategorize.map((value, index) => {
                if (index % 2 !== 0)
                  // if (index >= props.expenseToCategorize.length / 2)
                  return (
                    <Checkbox value={value[1]} onChange={handleChange}>
                      {value[0]}
                    </Checkbox>
                  );
              })}
            </Stack>
            <Stack
              spacing={[1, 5]}
              direction="column"
              style={{ display: revenueVisible ? "" : "none",minHeight:'100px' }}
            >
              {props.revenueToCategorize.map((value) => (
                <Checkbox value={value[1]} onChange={handleChange}>
                  {value[0]}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={categorySend}
          variant="transparent-with-icon"
          color="teal.300"
        >
          Enregistrer{" "}
        </Button>

        {/* <Button onClick={onClose}>Close</Button> */}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

}

export default CategoryModal;