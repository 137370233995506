import React, { useEffect, useState } from 'react';

import useCategory from './useCategory';


import Select , { StylesConfig }  from 'react-select';
import CreatableSelect from 'react-select/creatable';

import chroma from 'chroma-js';

import cashFlowApi from "utils/cashFlowApi.js";

export  function Category(props) {
  const [selectedOption, setSelectedOption] = useState(null);

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' //,     minHeight: '30px',     height: '30px',
      ,fontSize: '0.75rem'}),
      valueContainer: (styles, state) => ({
      ...styles,
      height: '30px',
      //padding: '0 6px'
    }),
            menuList: (styles) => ({
        ...styles,
        maxHeight: 100,
      }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma("#00B8D9");
    const fontSize = 'xs';
    return {
      ...styles,
       fontSize: '0.75rem',
      // backgroundColor: isDisabled
      //   ? undefined
      //   : isSelected
      //   ?"#00B8D9"
      //   : isFocused
      //   ? color.alpha(0.1).css()
      //   : undefined,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : "#00B8D9",
      // cursor: isDisabled ? 'not-allowed' : 'default',

      // ':active': {
      //   ...styles[':active'],
      //   backgroundColor: !isDisabled
      //     ? isSelected
      //       ? "#00B8D9"
      //       : color.alpha(0.3).css()
      //     : undefined,
      // },
    };
  },
  // input: (styles) => ({ ...styles, ...dot() }),
  // placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot("#00B8D9") }),
};


  
useEffect( ()=>{

    if(selectedOption!=null){
      console.log(props.company);
      console.log(Object.entries(selectedOption)[0][1]);
    }
 // props.onChange(selectedOption);
});
const [isLoading, setIsLoading] = useState(false);
const [options, setOptions] = useState(props.options);
const [value, setValue] = useState(props.selectedCategory);
const [categoryName, setCategoryName] = useState(null);
const createOption = (label: string, value: string) => ({
  label,
  value
  //value: label.toLowerCase().replace(/\W/g, ''),
});

const formatCreateLabel =(label)=>{
  return  'créer : '+label;
}

  const handleCreate = (inputValue: string) => {

   // setTimeout(() => {    }, 1000);
   console.log(inputValue);
    setIsLoading(true); 
    //setCategoryName(inputValue);
    sendCategory(inputValue);
 
  };

  function onChange(newValue) {
    console.log(newValue);
    setValue(newValue);
    console.log(props.company);
    console.log(props.reportId);

    props.onCategoryChange(newValue);
  }

  function sendCategory(categoryName) {
    var category_id = null;
   // var categoryType = props.categoryType;
    //   if(params.node.allLeafChildren[0]!=undefined){
    //  // category_id=params.node.allLeafChildren[0].data.category_id;
    //   type_id=params.node.allLeafChildren[0].data.type_id;
    //   }

    // if(params.node.groupData!=undefined)console.log(params.node.groupData.poste);
    // if(params.node.parent!=undefined)console.log(params.node.parent.key);

   // console.log(categoryName);
    console.log(props.categoryType.split("/").length);
    
    let type = "api/forecast_types/" + props.categoryType;
    if (props.categoryType.split("/").length > 1) type=props.categoryType;

   //   console.log(props.company);
     // console.log(props.reportId);
    let result = cashFlowApi.postCategory(categoryName, type, props.company);

    result.then((data) => {
      //console.log(categoryName);

      let value = data["@id"];
      let newOption = createOption(categoryName, value);
      newOption = { label: categoryName, value: value };
      console.log(newOption);
      setIsLoading(false);
      
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      props.onCategoryAdd(newOption);
      props.onCategoryChange(newOption);
    });
  }

// function isOptionUnique(prop) {
//   const { option, options, valueKey, labelKey } = prop;
//   return !options.find(opt => option[valueKey] === opt[valueKey])
// }
//console.log(props.options);

  return (
    <div className="App">
      <CreatableSelect
        isClearable
      //isOptionUnique={isOptionUnique}
        isOptionDisabled = {(option) => option.disabled}
        isLoading={isLoading}
        //onChange={(newValue) => setValue(newValue)}
        fontSize='0.75rem'
        width="auto" 
        defaultValue={props.selectedCategory}
        onChange={onChange}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        formatCreateLabel={formatCreateLabel}
        styles={colourStyles}
      />
    </div>
  );
}