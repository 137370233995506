import axios from "axios";
import jwt_decode from "jwt-decode";
import { updateNonNullChain } from "typescript";
require('dotenv').config();
//const api_url = "https://127.0.0.1:8000";


import { Redirect, useHistory } from "react-router-dom";


let api_url = "";
if(process.env.REACT_APP_SFC_URL !== undefined)api_url= process.env.REACT_APP_SFC_URL;

let current_url = location.hostname;
//console.log(current_url);
let env_mode=current_url.split(".");
console.log(env_mode[0]);

// if(env_mode[0]=='dev' )api_url='https://dev.'+api_url;
// else if(env_mode[0]!='localhost') api_url='https://'+api_url;


//console.log("api "+process.env.REACT_APP_SFC_URL);
//
//api_url = "https://dev.sfc.smartflowconnect.com"


const api_route = api_url + "/api";

function isLogged() {
  let token = localStorage.getItem("token");
  console.log("token" + token);
  if (token) return true;
  return false;
}

function login(email, password) {
  //console.log("Le lien a été cliqué.");
  const res = axios.post(api_url + "/authentication", {
    email: email,
    password: password,
  });
  res
    .then((data) => {
      //console.log(data.data.token);
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", data.data.refresh_token);
    })
    .catch((err) => {
      //console.log(err.response);
      return err;
      //throw err.response;
      //return data;
    });

  return res;
}

function autoLogin(url) {
  //console.log("Le lien a été cliqué.");
  const res = axios.get(api_url + "/login_check?"+url);
  res
    .then((data) => {
      //console.log(data.data.token);
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", data.data.refresh_token);
    })
    .catch((err) => {
      //console.log(err.response);
      return err;
      //throw err.response;
      //return data;
    });

  return res;
}

async function refreshToken(token) {

    console.log("refresh-sfc*** : "+localStorage.getItem("refreshToken"));
        if( localStorage.getItem("refreshToken")== null ){
            return '';
        }
    if(token===undefined)token=localStorage.getItem("refreshToken");
 
 console.log(token);
  const res = axios.post(api_url + "/authentication/refresh", {
    refresh_token: token,
  });

// axios.interceptors.response.use(response => {
//      console.log(response);
//    return response;
// }, error => {
//   if (error.response.status === 401) {
//    console.log(error.response);
//   }
//   return error;
// });

  res
    .then((data) => {
      console.log(data);
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refreshToken", data.data.refresh_token);
        // return data;
    })
    .catch((err) => {
              //  S
      console.log(err.response);
      if(err.response.status=='401'){
        console.log("no refresh");
        return res;
       //  this.props.history.push("/authentication/sign-in")
      // return (<Redirect to="/authentication/sign-in" />);
      }
      //return err.response;
      //throw err.response;
      //return data;
    });
return res;

}

function register(email, password) {
  const res = axios.post(api_route + "/users", {
    email: email,
    password: password,
  });
  res
    .then((data) => {
      //console.log(data.data.token);
      //localStorage.setItem("token", data.data.token);
    })
    .catch((err) => {
      console.log(err.response);
      throw err.response;
      // return err;
    });

  return res;
}

// async function getApi(url, token) {
//   const yourURL = api_route + url + ".jsonld";
//   console.log(yourURL);
//   let yourConfig = "";
//   let jwt = localStorage.getItem("token");

// const start = Math.round(Date.now() / 1000);

// console.log("tutu 1: " +start);

// let decoded=null;
//   try {
//       decoded = jwt_decode(jwt)
//     // valid token format
//   } catch(error) {
//    console.log("bouh" + error)
//   }


//  if(decoded!==null) {
   
//   console.log("tutu 2: " +decoded.exp);
//  let i =decoded.exp-start;
//  console.log("tutu 3: " + i);


//  if(i<120) {
//     if( localStorage.getItem("refreshToken")!="" ){
//       await  refreshToken();
//       // refreshToken();
//     //await sleep(4000);
//         jwt = localStorage.getItem("token");

//         console.log("tutu 4: bim" +jwt );
//     }
//   }

//  }

//   if (token)
//     yourConfig = {
//       headers: {
//         Authorization: "Bearer " + jwt,
//       },
//     };

//     axios.interceptors.response.use(response => {
//     console.log("catch :" +response);
//    return response;
// }, error => {
//   if (error.response.status === 401) {
//     console.log("bouh : refresh" );
//           refreshToken()
//           .then((response) => {
//              getApi(url, token).then((response)=>{   console.log("bouh : response" + response );
//             return  response; });

//           })
//           .catch((err) => {
//             console.log(err.response.data);
//             return err.response.data;
//           })
//   }
//   return error;
// });

// const { data } = await axios.get(yourURL, yourConfig);

//   return data;
// }




// data.catch((err) => {
//   console.log("catch :" +err.response.data.message);
//       if (err.response.data.message == "Expired JWT Token") {
//         refreshToken()
//           .then((response) => {
//             return getApi(url, token);
//           })
//           .catch((err) => {
//             console.log(err.response.data);
//             return err.response.data;
//           })
//         }}  );
      
//console.log("code : " + data.code);
//if(data.code)
  // .get(
  //   "http://127.0.0.1/7fallen/public/index.php/api/cards.jsonld?" + query
  // )
  // using .then, create a new promise which extracts the data
 // const dataPromise = promise;
    // .then((response) => {response.data ;
    // response.data.period.forEach(period => console.log("pp :" +period));})
    // .catch((err) => {
    //   if (err.response.data.message == "Expired JWT Token") {
    //     refreshToken()
    //       .then((response) => {
    //         return getApi(url, token);
    //       })
    //       .catch((err) => {
    //         console.log(err.response.data);
    //         return err.response.data;
    //       });
    //   } else {
    //     console.log(err.response.data);
    //     return err.response.data;
    //   }
    // });



// function callApi(url, body,method) {
//   //var body = { card: "/api/cards/" + id };

//   const dataPromise = axios({
//     method: method,
//     url: api_route + url,
//     data: JSON.stringify(body),
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": " application/json",
//     },
//   })
//     .then(function (response) {
//       //handle success
//       console.log(response);
//     })
//     .catch(function (response) {
//       //handle error
//       console.log(response);
//     });
//   return dataPromise;
// }


export default {
 // callApi,
//  getApi,
  register,
  login,
  refreshToken,
  autoLogin

};
