/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
    Input,
    FormLabel,
      Icon,
        Text,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
    ICellRendererParams,
} from "@chakra-ui/react";

import { AiFillLike, AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState, useRef } from 'react';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import cashFlowApi from "utils/cashFlowApi.js";
import paymentApi from "utils/paymentApi.js";

import "utils/style.css";


import ProductModal from "./components/ProductModal.js";
// function onCellValueChanged(params){
//   setRowChange(params)
//   console.log(params);
// }
import {
    useDisclosure,
  Modal,
  Switch,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';


function Index(props) {
 const { isOpen, onOpen, onClose } = useDisclosure();
 //const { , ,  } = useDisclosure();
 const { 
    isOpen: isProductModalOpen, 
    onOpen: onProductModalOpen, 
    onClose: onProductModalClose 
} = useDisclosure();

    const   onCellValueChanged= (params)=>{
           setRowChange(arr => [...arr, params]);
            //console.log(params);
           // isOpen=true;

       }
   // const history = useHistory();
  function redirect() {
    console.log("dssdf");
    //return <Redirect push to="/authentication/sign-in" />;
        let url = "/authentication/sign-in";
   // history.push(url);
  }
    function clickModal(){
    

       // console.log(params);
      //   setName('');
      //   setAmount('');
      //  // setPrice(params.data.prices[0].amount);
      //   setPrices();
      //  // setStartDate(params.data.next_date);
      //   setPeriodicitySelected();
      //   setStart();
        setOperationType("post");
        // onOpen();

         
           //isOpen();
     onProductModalOpen();

        
  }


function currencyFormatter(params) {
  return '£' + formatNumber(params.value);
}


  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [columns2, setColumns2] = useState([]);


  
  const [rowData2, setRowData2] = useState([]);

  const [product, setProduct] = useState([]);


  const [operationType, setOperationType] = useState([]);


  let history = useHistory();

  const gridOptions = {
    domLayout: 'autoHeight',
    rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  {
      console.log(params.data);

        setProduct(params.data);

        onProductModalOpen(); 
           //isOpen();
      }
  }

  
  const gridPricesOptions = {
     domLayout: 'autoHeight',
     rowClassRules: {

        'bouh': function(params) {
          let response =false;
          if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
          return response;},
                  
    
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  {
        // console.log(params);
        // setName(params.data.name);
        // setAmount(params.data.mean);
        // setPrices(params.data.prices[0].amount);

        // setPeriodicitySelected(params.data.interval);
        //  onOpen();
        //  setStart(params.data.next_date);
  
      }
  }

    const gridOptions2 = {
        domLayout: 'autoHeight',
      rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
     // onCellDoubleClicked: (params) =>  console.log(params)
  }

let [products,setProducts]=useState([]);

let [periodicities,setPeriodicities]=useState([]);
   useEffect(() => {
      let isSubscribed = true;
          ///console.log("length"+categories.length);
         if (products.length==0) {

        //cashFlowApi.getAccount('15121')
      //  let getCategories = cashFlowApi.getCategories();       
        let getProducts = paymentApi.getProducts();   
  
        getProducts.then( (getProducts) =>{

          Object.entries(getProducts["hydra:member"]).forEach(([key, value]) => {
              products.push({value:value["@id"],label:value.name,prices:value.prices});
            // Object.entries(value).forEach(([key, value]) => {
            //         console.log(key + ' : ' + value);
       
            // });
            //console.log(value.prices[0].amount);
         });
               //}
               let getPeriodcities=paymentApi.getPeriodicities();



               getPeriodcities.then( (period)=> {
                  setPeriodicities(period["hydra:member"]);  
               });
           
         setProducts(products);
        // console.log("length2"+products.length);

          let col=[];
          let colNum=0;
          //setColumns([report.periodColumn]);
          col.push({headerName: 'Nom', field: 'label', sortable: true ,  width: 180}); 

          // col.push({headerName: "Montant", field: 'mean', sortable: true ,    width: 100,  valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 2})',type: 'rightAligned' }); 
          // col.push({headerName: "Périodicité", field: 'periodicity_label', sortable: true ,    width: 200,  valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 2})',type: 'rightAligned' }); 

          setColumns(col);
          setColumns2(col);
          let row=[];

          let row_col=[];
         // let row_col2=[];

          //let datas=report.test;
         // let revenues=report.forecast_revenue;



          Object.entries(products).forEach(([key, value]) => {
            console.log("value"+value);
            //if(value.active==true) 
            row_col.push(value);
            });

          // let expenses=report.forecast_expense;
          // Object.entries(expenses).forEach(([key, value]) => {
          //   if(value.actdoive==true) row_col.push(value);
          //   });


          setRowData(row_col);
        //  setRowData2(row_col2);

        });
 
  
      }        else redirect();
      }, [products]);



 if (!rowData) {
     return null;
  }
  else  {
//     const numrows=5
//     const rows = [];
// for (let i = 0; i < numrows; i++) {
//     rows.push(                 <FormControl>
//                     <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
//                       Montant
//                     </FormLabel>
//                     <Input placeholder="" fontSize="xs" defaultValue={price} />
//                   </FormControl>);
// }



    return (


<div>

    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
        {/* <ModalW></ModalW> */}

         <ProductModal product={product} periodicities={periodicities}
          isProductModalOpen={isProductModalOpen} onProductModalOpen={onProductModalOpen} onProductModalClose={onProductModalClose} ></ProductModal>

      <Stack>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100px"
        mb="24px"
      >
       
                <Button
                    variant="outline"
                    colorScheme="teal"
                   // minW="110px"
                    h="36px"
                    fontSize="xs"
                    px="1.5rem"
                     onClick={()=>clickModal()}
                  >
                                      <Icon
                    as={AiOutlinePlus}
                    w="18px"
                    h="18px"
                    fontWeight="bold"
                    me="4px"
                  />

                    Nouveau
                  </Button>
              </Flex>
              </Stack>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >
    <div className="ag-theme-alpine" style={{maxHeight:"75vh", width: "80vw",top:400}}>
           <AgGridReact
               rowData={rowData}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions}
                           >
            {columns.map(column => (
              <AgGridColumn {...column} key={column.field} />
            ))}

           </AgGridReact>



           {/* <AgGridReact
               rowData={rowData2}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions2}
                           >
          	{columns2.map(column => (
            <AgGridColumn {...column} key={column.field} />
          ))}                        
          </AgGridReact> */}

       </div>


      </Flex>

    </Flex>
    </div>
  );
}

}

export default Index;
