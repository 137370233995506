/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    ChakraProvider,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
    Input,
    FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
    extendTheme,
  Stack,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from 'react';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { ColDef, Grid, GridOptions } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import cashFlowApi from "utils/cashFlowApi.js";
import paymentApi from "utils/paymentApi.js";

import "utils/style.css";

import intervals from 'utils/intervals.json';


import { StartDate } from "./components/StartDate";
import { Category } from "views/Components/Category";
import { Periodicity } from "./components/Periodicity";
import { VariableAmount } from "./components/VariableAmount";
import {ExcludeForecast } from "./components/ExcludeForecast";


import{MonthValue} from "views/Components/MonthValue";
// function onCellValueChanged(params){
//   setRowChange(params)
//   console.log(params);
// }
import {
    useDisclosure,
  Modal,
  Switch,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { convertToObject } from "typescript";


function Starter(props) {
 const { isOpen, onOpen, onClose } = useDisclosure()
    const   onCellValueChanged= (params)=>{
           setRowChange(arr => [...arr, params]);
            //console.log(params);
           // isOpen=true;

       }
   // const history = useHistory();
  function redirect() {
   // console.log("dssdf");
    //return <Redirect push to="/authentication/sign-in" />;
        let url = "/authentication/sign-in";
   // history.push(url);
  }
// return <Redirect push to="/forecast" />;


function currencyFormatter(params) {
  return '£' + formatNumber(params.value);
}
  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [revenuesRow, setRevenuesRow] = useState([]);
  const [expensesRow, setExpensesRow] = useState([]);
  
  const [row, setRow] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [rowData2, setRowData2] = useState([]);

  const [name, setName] = useState([]);
  const [mappedName, setMappedName] = useState([]);
  const [amount, setAmount] = useState([]);
  const [months, setMonths] = useState([]);
  const [monthPeriods, setMonthPeriods] = useState([]);
  
  const [periodicitySelected, setPeriodicitySelected] = useState([]);
  
  let history = useHistory();
  let  monthToShow = [];
  let  monthstoPeriod=[];

  const [bankAccount, setBankAccount] = useState();
  const [actualReport, setActualReport] = useState();
  
  /* state to hidde a row */
  const [removeRow, setRemoveRow] = useState(false);
  /* type of row displayed : revenue or exepense */
  const [displayRow, setDisplayRow] = useState('');

const [januaryAmount,setJanuaryAmount]=useState('');
const januaryOnChange=(value)=>{
  console.log("montant :  "+value);
  setJanuaryAmount(value);
}

const [februaryAmount,setFebruaryAmount]=useState('');
const februaryOnChange=(value)=>{
 console.log("montant :  "+value);
    setFebruaryAmount(value);
}

const [marchAmount,setMarchAmount]=useState('');
const marchOnChange=(value)=>{
 console.log("montant :  "+value);
    setMarchAmount(value);
}

const [aprilAmount,setAprilAmount]=useState('');
const aprilOnChange=(value)=>{
 console.log("montant :  "+value);
    setAprilAmount(value);
}

const [mayAmount,setMayAmount]=useState('');
const mayOnChange=(value)=>{
    console.log("montant :  "+value);
    setMayAmount(value);
}

const [juneAmount,setJuneAmount]=useState('');
const juneOnChange=(value)=>{
    console.log("montant :  "+value);
    setJuneAmount(value);
}

const [julyAmount,setJulyAmount]=useState('');
const julyOnChange=(value)=>{
 console.log("montant :  "+value);
    setJulyAmount(value);
}

const [augustAmount,setAugustAmount]=useState('');
const augustOnChange=(value)=>{
  console.log("montant :  "+value);
    setAugustAmount(value);
}

const [septemberAmount,setSeptemberAmount]=useState('');
const septemberOnChange=(value)=>{
    console.log("montant :  "+value);
    setSeptemberAmount(value);
}

const [octoberAmount,setOctoberAmount]=useState('');
const octoberOnChange=(value)=>{
     console.log("montant :  "+value);
    setOctoberAmount(value);
}

const [novemberAmount,setNovemberAmount]=useState('');
const novemberOnChange=(value)=>{
        console.log("montant :  "+value);
   setNovemberAmount(value);
}

const [decemberAmount,setDecemberAmount]=useState('');
const decemberOnChange=(value)=>{
       console.log("montant :  "+value);
    setDecemberAmount(value);
}


function resetMonths(){
  setJanuaryAmount();
  setFebruaryAmount();
  setMarchAmount();
  setAprilAmount();
  setMayAmount();
  setJuneAmount();
  setJulyAmount();
  setAugustAmount();
  setSeptemberAmount();
  setOctoberAmount(); 
  setNovemberAmount();
  setDecemberAmount();
}

  const gridRef = useRef();

  const gridOptions = {
      domLayout: 'autoHeight',
      rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  {
          openEntryModal(params);
      }
  }

    const gridOptions2 = {
        domLayout: 'autoHeight',
        rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
     // onCellDoubleClicked: (params) =>  console.log(params)
  }

  function openEntryModal(params){
        showRow();
        resetMonths();
        setRow(params.data.num_line);
        console.log(params);
        setName(params.data.num);
        setMappedName(params.data.num);
        setAmount(params.data.mean);
        setMonths();
        setMonthPeriods();
        monthToShow=[];
        monthstoPeriod=[];

       let actual_month="";
  
        Object.entries(params.data.amount_by_month).forEach(([key, value]) => {
              actual_month=key;
              // console.log("actual_month 1 "+actual_month);
              Object.entries(value).forEach(([key, value]) => {
              monthstoPeriod.push(actual_month+':'+value.period);
              eval("set"+actual_month+'Amount')(value.amount);

        });

         });
     setMonths(monthToShow);
     setMonthPeriods(monthstoPeriod);

    if(params.data.mean<0){
      setTitle('Dépense');
      setCategories(expenseCategories);
      setCategory("/api/forecast_categories/390265b9-c8c3-11ed-9c7b-00155d01903e");
      setSelectedCategory({ value: "/api/forecast_categories/390265b9-c8c3-11ed-9c7b-00155d01903e", label: 'Loyers' });
    }
    else {
      setTitle('Revenu');
      setCategories(revenueCategories);
        setCategory("/api/forecast_categories/39026c76-c8c3-11ed-9c7b-00155d01903e");
        setSelectedCategory({ value: "/api/forecast_categories/39026c76-c8c3-11ed-9c7b-00155d01903e", label: 'Ventes' });
    }

     if(params.data.ecart==0)setShowVariableAmount(0);
       let p={ value: "/api/intervals/"+params.data.interval, label: params.data.periodicity_label };
        //console.log(p);
        setPeriodicitySelected(p);
        setPeriodicity("/api/intervals/"+params.data.interval);
        setStart(params.data.next_date);
        onOpen();

  }

  const sendEntry=()=>{
   // console.log("boum"); 
    
    //let report="api/reports/"+actualReport;
    let report=actualReport;
    //console.log("report " +actualReport);
    let type="api/forecast_types/2";
    let newAmount=amount;

    let newStartDate;
    let entryPeriods=[];
    if(newAmount<0){
        type="api/forecast_types/1";
        newAmount=-newAmount;
    }
    // let mappedEntry =["label:"+mappedName,"bankAccount:/api/accounts_infos/"+bankAccount];
     // let mappedEntry ="{label:"+name+,"bankAccount:/api/accounts_infos/"+bankAccount+"}";
    if(name!=""){
  
      //let bank="/api/accounts_infos/"+bankAccount;
    //  mappedEntry.push(label:name,bankAccount:"/api/accounts_infos/"+bankAccount);
    }

  let newPeriodicity=periodicity;
console.log('showVariableAmount' + showVariableAmount);
  if(showVariableAmount){
     //tricks for api
      newPeriodicity='/api/intervals/10';
      //console.log(monthPeriods );
      monthPeriods.forEach((key) => {
        /* key = 200.49:2022-12-01 => amount, last date, after previous month */
        console.log(key);
        key=key.split(':');
        newAmount=eval(key[0].toLowerCase()+'Amount');
        if(newAmount<0){
            type="api/forecast_types/1";
            newAmount=-newAmount;
        }
        else {
            type="api/forecast_types/2";
        }
        newStartDate=key[1];
        //let periodicity=
        //console.log("value "+key[0] +' '+eval(key[0]+'Amount') +',date :' +key[0],);
        entryPeriods.push({amount:newAmount.toString(),recurringInterval:newPeriodicity,period:key[1]});
       //cashFlowApi.postEntry(report,type,newAmount.toString(),name,category,newPeriodicity,recurring,newStartDate,finish);

        });
   // console.log();
  }
    /*
    * Mask row into AgGrid
    */
    let a = rowData;
    console.log(a);
    Object.entries(a).forEach(([key, value]) => {
        if(value.num_line==(row)){
           // console.log(key +" " + row +" : " + value.num_line);
            a.splice(key,1);
        }
      });
    setRowData(a);
    gridRef.current.api.setRowData(a);

    cashFlowApi.postEntry(report,type,newAmount.toString(),name,category,newPeriodicity,recurring,start,finish,entryPeriods,mappedName,bankAccount);

    onClose();
  }

const monthsToShow=12;

const [category,setCategory]=useState(1);
const [selectedCategory,setSelectedCategory]=useState(1);

function showRow(){
    console.log(rowData);
}

const onCategoryChange= (value)=>{
   //console.log("bam jjj" + value);
   if(value!= null) {
    //console.log(Object.entries(value)[0][1]);
    setCategory(Object.entries(value)[0][1]);
   } 
}
let [categories,setCategories]=useState([]);
let [revenueCategories,setRevenueCategories]=useState([]);
let [expenseCategories,setExpenseCategories]=useState([]);
let [title,setTitle]=useState([]);
useEffect(() => {
      let isSubscribed = true;
          ///console.log("length"+categories.length);
          let revenuesCategories;
          let expensesCategories=[];
      if (expenseCategories.length==0) {
        //cashFlowApi.getAccount('15121')
        let getCategories = cashFlowApi.getCategories();       
        //let products = paymentApi.getProducts();   
      
        getCategories.then( (getCategories) =>{

          Object.entries(getCategories["hydra:member"]).forEach(([key, value]) => {
            if(value["type"]["@id"]=="/api/forecast_types/1")expenseCategories.push({value:value["@id"],label:value.name});
            else  revenueCategories.push({value:value["@id"],label:value.name});
            
            //setCategory(value["@id"]);

         });
  
        setExpenseCategories(expenseCategories);
        setRevenueCategories(revenueCategories);
        //console.log("length2"+categories.length);

        });
      } else redirect();
      }, [isSubscribed]);

let [periodicities,setPeriodicities]=useState([]);
   useEffect(() => {
      let isSubscribed = true;
          ///console.log("length"+categories.length);
      if (periodicities.length==0) {
         Object.entries( intervals["hydra:member"]).forEach(([key, value]) => {
           periodicities.push({value:value["@id"],label:value.label});
            });

      } else redirect();
      }, [periodicities]);

const [recurring,setRecurring]=useState(true);
const switchChange= (value)=>{
   //console.log("bam" + value.checked);
  setRecurring(value);
}
const nameChange= (e)=>{
  console.log("name" + e.target.value);
  setName(e.target.value);
}

const [showVariableAmount,setShowVariableAmount]=useState(1);
const variableAmountChange= (value)=>{
  console.log("variableAmountChange" + value.checked);
  console.log("variableAmountChange avant " + showVariableAmount);
  
  setShowVariableAmount(value.checked);
  console.log("variableAmountChange après" + showVariableAmount);
}

const [periodicity,setPeriodicity]=useState(true);

const onPeriodicityChange= (value)=>{
   if(value!= null) {
    //console.log(Object.entries(value)[0][1]);
      setPeriodicity(Object.entries(value)[0][1]);
   }
}

const [start,setStart]=useState('');
const startOnChange=(value)=>{
   // console.log("date"+value);
   // setName(value);
  setStart(value);
}

const [finish,setFinish]=useState('');
const finishOnChange=(value)=>{
   // console.log("date"+value);
   // setName(value);
  setFinish(value);
}

let isSubscribed = true;

const bankClick= ()=>{
  //setBankAccount("1");
}
   useEffect(() => {
        // setBankAccount(16);
         if (isSubscribed) {

        //cashFlowApi.getAccount('15121')
         let reports = cashFlowApi.getReports();   

      
        reports.then((reports)=>{
          //console.log("name "+reports["hydra:member"][0]["name"]);
          setActualReport(reports["hydra:member"][0]["@id"]);
         // console.log("id "+reports["hydra:member"][0]["accounts"][0]["id"]);
          setBankAccount(reports["hydra:member"][0]["accounts"][0]["id"]);
          Object.entries(reports["hydra:member"][0]).forEach(([key, value]) => {
            //console.log("llll"+value);

            //value.push('key :'+ key);
            //if(value.active==true) row_col.push(value);
            });
          });
      
        //     console.log("call report");
    // bankAccount=  14631;


   //      let bankAccount=16  ;

   if( bankAccount!=undefined){
      let report = cashFlowApi.getAccount(bankAccount);  
        report.then( (report) =>{
      //  console.log("report... "+report)

         // history.push("/authentication/sign-up");

          let col=[];
          let colNum=0;
          //setColumns([report.periodColumn]);
          col.push({headerName: 'Poste', field: 'num', sortable: true ,  width: 180}); 
          col.push({headerName: "Montant", field: 'mean', sortable: true ,    width: 100,  valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 2})',type: 'rightAligned' }); 
          col.push({headerName: "Périodicité", field: 'periodicity_label', sortable: true ,    width: 200,  valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 2})',type: 'rightAligned' }); 
          col.push({headerName: "Masquer", field: 'num', sortable: false ,    width: 100, type: 'rightAligned'
        ,   cellRenderer: ExcludeForecast,
          cellRendererParams: {
            clicked: function(data) {
              console.log(data[0]);
             // rowHidde(data[0]);
              setRemoveRow(data[0]+'_'+data[2]);
             cashFlowApi.excludeForecast(data[1]);
             // alert(`${data[1]} was clicked`);
            },}
        }); 

          setColumns(col);
          setColumns2(col);
          let row=[];

          let revenuesRow=[];
          let expensesRow=[];
          let row_col2=[];

          //let datas=report.test;
          let revenues=report.forecast_revenue;
          Object.entries(revenues).forEach(([key, value]) => {
            //console.log(value);
            //value.push('key :'+ key);
            if(value.active==true && value.is_excluded==false && value.is_mapped==false ) revenuesRow.push(value);
            });
          setRevenuesRow(revenuesRow);

          let expenses=report.forecast_expense;
          Object.entries(expenses).forEach(([key, value]) => {
            if(value.active==true && value.is_excluded==false && value.is_mapped==false) expensesRow.push(value);
            });
          setExpensesRow(expensesRow);
          
          /* show revenues first */

          if(revenuesRow.length>0){
            setRowData(revenuesRow);
          }
          else if (expensesRow.length>0) {
            setRowData(expensesRow);
          } 
          //else  launchForecast();
          

          setRowData2(row_col2);
          //switchTab();
        }).catch(e => {
    console.log(e);
});
       // });
}
      }
    return () => isSubscribed = false;
   }, [bankAccount]);


const revenuesClick= ()=>{
  setDisplayRow('revenue'),
  setRowData(revenuesRow);
}

const expensesClick= ()=>{
  setDisplayRow('expense'),
  setRowData(expensesRow);
}

  useEffect(() => {
    if(removeRow!==false){
          //  console.log(displayRow);
          //    console.log(revenuesRow);
          //  console.log(expensesRow);
           console.log("removeRow launched");
      let removed=removeRow.split("_");

      if(removed[1]=='revenue'){
        revenuesRow.splice(removed[0],1);
         setRowData(revenuesRow);
        gridRef.current.api.setRowData(revenuesRow);
      }
      else {
       // console.log(expensesRow);
        expensesRow.splice(removed[0],1);
        setRowData(expensesRow);
         gridRef.current.api.setRowData(expensesRow);
      }
      switchTab();
    }
      }, [removeRow]);

useEffect(() => {
  // console.log("rowData");
  // console.log(displayRow);
  // console.log(revenuesRow);
  // console.log(expensesRow);
  console.log("RowData changed");
  switchTab();
}, [rowData]);

useEffect(() => {
  if(displayRow=='revenue') setRowData(revenuesRow);
   else  setRowData(expensesRow);
}, [displayRow]);

function switchTab(){
  console.log("SwitchTab launch");
  if(removeRow!==false){
    console.log(revenuesRow);
    console.log(expensesRow);
    if(revenuesRow.length>0){
      setDisplayRow('revenue');
      }
    else if (expensesRow.length>0) {
      //alert('d"pense');
      setDisplayRow('expense');
      } 
    else  {
      //alert('terminé');
      launchForecast();
    }
  }
  }

  function launchForecast(){
      props.history.push("/forecast");
  }

   let Results = () => (
    <>



</>
   );

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

 const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

let company ="api/companies/996b62df-2168-4b8e-b1e9-694c5437b1ce";

 if (!rowData) {
     return null;
  }
  else  {

    return (
<div>

    <ChakraProvider theme={theme}>
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
        {/* <ModalW></ModalW> */}

              <Modal  size="xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
                <Stack direction="row" spacing="24px">
                  <FormControl>
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Libellé
                    </FormLabel>
                    <Input  fontSize="xs"  placeholder=""  defaultValue={name} onChange={(e)=>nameChange(e)}/>
                  </FormControl>
                </Stack>

            <Stack direction="row" spacing="24px">

             <FormControl>
                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Categorie
                  </FormLabel>
                <Category options={categories} company={company}   selectedCategory={ selectedCategory } onChange={onCategoryChange}></Category>
              </FormControl>
            </Stack>

            <FormControl  alignItems='center'>

                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Montant variable
                 </FormLabel>
                
                    <Switch colorScheme="cyan" defaultChecked={showVariableAmount} onChange={(e)=>variableAmountChange(e.target)} />
                   
            </FormControl>

       <Stack direction="column" spacing="24px" style={{display: showVariableAmount ? 'none' : '' }}>
                   
                  <Stack direction="row" spacing="24px" >
                    <FormControl>
                    <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                      Montant
                    </FormLabel>
                    <Input fontSize="xs"  placeholder="" defaultValue={amount}   />
              </FormControl>
                 <FormControl>
                <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                     Pochaine date
                   </FormLabel>
                 
                 <StartDate label="Début" onChange={startOnChange}  value={start}></StartDate>

           </FormControl>
  
        
           </Stack>
            <Stack direction="row" spacing="24px">
    <FormControl>

                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Récurrent
                    </FormLabel>
                    <Switch colorScheme="cyan" defaultIsChecked onChange={(e)=>switchChange(e.target)} />

      </FormControl>
       


                  <FormControl>
          
                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Périodicité
                    </FormLabel>
                    <Periodicity options={periodicities} selectedPeriodicity={periodicitySelected} onChange={onPeriodicityChange}></Periodicity>

              </FormControl>
                  </Stack>
             
                  <FormControl //variant="floating"
                   >
        
                     <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                      Jusqu'au
                    </FormLabel>
                  <StartDate label="Fin" onChange={finishOnChange} value={finish}></StartDate>
              
              
                  </FormControl>
         </Stack>
      {/* {showVariableAmount ? <Results /> : null }       */}
<Stack direction="column" spacing="20px" w="100%" style={{display: showVariableAmount ? '' : 'none' }} >

  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={januaryAmount} label={'Janvier'}  onChange={januaryOnChange} />
  <MonthValue defaultValue={julyAmount} label={'Juillet'} onChange={julyOnChange}  />
  </Stack>
  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={februaryAmount} label={'Février'} onChange={februaryOnChange}  />
  <MonthValue defaultValue={augustAmount} label={'Aout'} onChange={augustOnChange}  />
  </Stack>
  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={marchAmount} label={'Mars'} onChange={marchOnChange} />
  <MonthValue defaultValue={septemberAmount} label={'Septembre'} onChange={septemberOnChange} />
  </Stack>
  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={aprilAmount} label={'Avril'} onChange={aprilOnChange}/>
  <MonthValue defaultValue={octoberAmount} label={'Octobre'} onChange={octoberOnChange}/>
  </Stack>
  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={mayAmount} label={'Mai'}onChange={mayOnChange} />
  <MonthValue defaultValue={novemberAmount} label={'Novembre'} onChange={novemberOnChange} />
  </Stack>
  <Stack direction={{ sm: "column", md: "row" }} spacing="30px">
  <MonthValue defaultValue={juneAmount} label={'Juin'} onChange={juneOnChange}/>
  <MonthValue defaultValue={decemberAmount} label={'Décembre'} onChange={decemberOnChange} />
  </Stack>
</Stack>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Close</Button> */}
            <Button onClick={sendEntry} variant="transparent-with-icon" color="teal.300" >Enregistrer  </Button>        
          </ModalFooter>
        </ModalContent>
      </Modal>
          {/* <Button  onClick={bankClick} >Bank</Button>:{bankAccount} */}
          <Button  onClick={revenuesClick} >Revenus</Button>
          <Button  onClick={expensesClick} >Dépenses</Button>

        {/* <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/album/327784717" width="800" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
      
        <iframe title="deezer-widget" src="https://widget.deezer.com/widget/dark/album/334903217" width="800" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
       */}

 
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >

      
    <div className="ag-theme-alpine" style={{maxHeight:"75vh", width: "80vw",top:400}}>
           <AgGridReact
           ref={gridRef}
               rowData={rowData}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions}
                           >
            {columns.map(column => (
              <AgGridColumn {...column} key={column.field} />
            ))}

           </AgGridReact>



           {/* <AgGridReact
               rowData={rowData2}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions2}
                           >
          	{columns2.map(column => (
            <AgGridColumn {...column} key={column.field} />
          ))}                        
          </AgGridReact> */}

       </div>


      </Flex>

    </Flex>
    </ChakraProvider>
    </div>
  );
}

}

export default Starter;
