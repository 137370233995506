import React, {useEffect, useState , useRef }from "react";

import {  Calendar,
  CalendarDefaultTheme,
  CalendarControls,
  CalendarPrevButton,
  CalendarNextButton,
  CalendarMonths,
  CalendarMonth,
  CalendarMonthName,
  CalendarWeek,
  CalendarDays,
} from '@uselessdev/datepicker';

// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent ,
  PopoverBody 
} from "@chakra-ui/react";


import { ChakraProvider } from '@chakra-ui/react';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';

export function StartDate(props){
  const calendarRef = useRef(null);
  const [date, setDate] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [value, setValue] = useState(props.value);


  const initialRef = useRef(null);

    const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
        console.log(target.value);
     let newDate= format(target.value, 'yyyy-MM-dd');
         console.log(newDate);
     props.onChange(newDate.toString());
    // if (match(target.value)) {
    //   onClose();
    // }
  };

  const handleSelectDate = (date: CalendarDate) => {
    setDate(date);
    setValue(() => ( format(date, 'dd/MM/yyyy') : ''));
    props.onChange(format(date, 'yyyy-MM-dd').toString());
    onClose();
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });
   useEffect(() => {
   // onSelectDate(props.value);
    // setDate(props.value);
  //  console.log(props.value);
    //props.onChange(date);
   });

  return (

      <ChakraProvider theme={CalendarDefaultTheme}>
      
      <Box>
      <Popover
        placement="bottom-end"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isLazy
      >
        <PopoverTrigger>
          <Box onClick={onOpen} ref={initialRef}>
            <Input
              placeholder="jj/mm/aaaa"
              value={value}
              onChange={handleInputChange}
              htmlSize={9}
              width="auto"
              fontSize="xs"
            />
          </Box>
        </PopoverTrigger>

        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: 'none' }}
          ref={calendarRef}
        >
          <Calendar
            value={{ start: date }}
             locale={fr}
            onSelectDate={handleSelectDate}
            singleDateSelection
          >
            <PopoverBody p={0}>
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>
            </PopoverBody>
          </Calendar>
        </PopoverContent>
      </Popover>
    </Box>
    </ChakraProvider>
  )

  }