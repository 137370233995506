/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//import { FaBeer } from "react-icons/fa";
import {
  FaCheckCircle,
  FaLightbulb,
  FaPaypal,
  FaPlay,
  FaRegLightbulb,
  FaShare,
  FaUser,
  FaSignOutAlt,
  FaSignInAlt,
  FaList,
  FaUniversity,
 FaEuroSign,
 FaExchange
} from "react-icons/fa";

// Chakra imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
    Grid,
  Link,
  Stack,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";

import { useCallback, useEffect, useState, useRef,useMemo } from 'react';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import IconBox from "components/Icons/IconBox";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";
//import { AgGridColumn, AgGridReact } from "ag-grid-react";
import {AgGridReact} from 'ag-grid-react';
//import { AgGridColumn, AgGridReact } from "ag-grid-enterprise";
//import 'ag-grid-enterprise';
import 'ag-grid-enterprise'; // the AG Grid React Component
//import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import cashFlowApi from "utils/cashFlowApi.js";
import "utils/style.css";
import CategoryModal from "views/Components/CategoryModal.js";

import EntryModal from "views/Components/EntryModal.js";

//import SimpleCellRenderer from "./simpleCellRenderer.jsx";

import CategoryCellRenderer from "./CategoryCellRenderer.jsx";

import ExchangeCellRenderer from "./ExchangeCellRenderer.jsx";
import DeleteCellRenderer from "./DeleteCellRenderer.jsx";

//import CategoryRenderer from "./CategoryRenderer.js";

import CategoryEditModal from "views/Components/CategoryEditModal.js";
import CategoryNewModal  from "views/Components/CategoryNewModal.js";

import { Redirect, useHistory } from "react-router-dom";
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';

//import ModalW from './modal.js';
// function onCellValueChanged(params){
//   setRowChange(params)
//   console.log(params);
// }
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';
// import { MdBugReport } from "react-icons/md";
// import { FaRegClosedCaptioning } from "react-icons/fa";
// import { types } from "@babel/core";



  let modalIsOpen = false;
  let forecastEntries = [];
  let entryByExpenseCategories=[];
let entryByRevenueCategories = [];

function Categories(props) {

      let path = props.location.pathname.split("/");
    console.log("path ::" + path);
    
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
// const { isOpen: isEntryOpen , onOpen: onEntryOpen, onClose: onEntryClose } = useDisclosure();
// const { isOpen: isCategoryOpen , onOpen: onCategoryOpen, onClose: onCategoryClose } = useDisclosure();

  const entryModal = useDisclosure();
  const categoryModal = useDisclosure();
  const categoryEditModal =useDisclosure();
  const categoryNewModal =useDisclosure();

  var defaultParams = {
    'data' : {
        'poste' : '',
        'category_id ':''
    }
};

  const [thisRowParams, setThisRowParams] = useState(defaultParams);
  const [params, setParams] = useState(defaultParams);
function currencyFormatter(params) {
  return '£' + formatNumber(params.value);
}
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);


  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [columns2, setColumns2] = useState([]);
  const [rowData2, setRowData2] = useState([]);
  const [startRow, setStartRow] = useState([]);
  const [endRow, setEndRow] = useState([]);
         


let history = useHistory();

let [categories,setCategories]=useState([]);
let [selectedCategory, setSelectedCategory] = useState(null);
let [revenueToCategorize,setRevenueToCategorize]=useState([]);
let [expenseToCategorize,setExpenseToCategorize]=useState([]);
let [monthsColums, setMonthsColums] = useState([]);

//let [startDate, setStartDate] = useState(null);
let [startDateFormated, setStartDateFormated] = useState(null);
//let [finishDate, setFinishDate] = useState(null);
let [finishDateFormated, setFinishDateFormated] = useState(null);

let [recurringInterval, setRecurringInterval] = useState(null);
let [recurring, setRecurring] = useState(true);
let [fixedAmount, setFixedAmount] = useState();
let [vatSelected, setVatSelected] = useState();

let [disabled, setDisabled] = useState();

let [bankBalance, setBankBalance] = useState();
let [bankBalanceDate, setBankBalanceDate] = useState();

const monthsToShow=12;

  function goForecast(){
     props.history.push("/forecast");
  }

const isCellEditable = (params) => {
    let isEditable=false;
    console.log(params);
    if(params.node.rowPinned  === undefined && params.data.poste!='TVA à reverser')isEditable=true;
  return isEditable;
};

// const isCellEditable = (params) => {
//     let isEditable=false;
//    if(debug)console.log(params);
//     //if(params.node.rowPinned  === undefined && params.data.poste!='TVA à reverser')isEditable=true;

//             if (params.data != undefined) {
//                    if (
//                      params.data.poste != undefined 
//                      && params.colDef.field !== "poste"
//                      && params.data.poste !== "TVA à reverser"
//                      && params.data.poste !== "Solde début"
//                      &&  params.data.poste !== "Solde fin") return true;
//                   }else  isEditable =true;
//   return isEditable;
// };

var rowDrag = function (params) {
  let result=false;
  // only rows that are NOT groups should be draggable
  if(params.node.group === false && params.data.poste !=="TVA à reverser")result=true;
  return result;
};

  const onRowDragMove = useCallback((event) => {
    var movingNode = event.node;
    var overNode = event.overNode;
    // find out what country group we are hovering over
    var groupCategory;
    console.log(event);
    if (overNode.group) {
      // if over a group, we take the group key (which will be the
      // country as we are grouping by country)
    //  console.log(overNode.parent.key );
      groupCategory = overNode.key;
    } else {
      // if over a non-group, we take the country directly
      groupCategory = overNode.data.category;
    }
    var needToChangeParent = movingNode.data.category !== groupCategory;
    if (needToChangeParent && overNode.parent.key==movingNode.data.type) {
      var movingData = movingNode.data;
      console.log(movingData);
      movingData.category = groupCategory;
      console.log(groupCategory);
      gridRef.current.api.applyTransaction({
        update: [movingData],
      });
      gridRef.current.api.clearFocusedCell();
    }
  }, []);

function updateGrid(categoryId,categoryName,typeId){

  var rowNode =   gridRef.current.api.getDisplayedRowAtIndex(thisRowParams.rowIndex);

  const itemsToUpdate = [];
  
     const data = rowNode.data;
     data.label= categoryName;
     // data.category_id=categoryId;
     // if(typeId==1) data.type='Décaissements';
     // else  data.type='Encaissements';
    //  // data.poste=response.name;

    //  //data.disabled=response.disabled;
    //      if (entryPeriods.length < 1){
    //         data.periods=null
    //       //  rowNode.setDataValue("periods", null);
    // } 
      itemsToUpdate.push(data);
    const res = gridRef.current.api.applyTransaction({ update: itemsToUpdate });
}

function onRowDragEnd(event) {
    var movingNode = event.node;
    var overNode = event.overNode;
    // find out what country group we are hovering over
    var groupCategory;
    var groupCategoryId;
    var groupTypeName;
    var groupTypeId;
    let entries=[];

      //entries=entriesByCategories[groupCategoryId];
    console.log(event);
    if (overNode.group) {
      // if over a group, we take the group key (which will be the
      // country as we are grouping by country)
    //  console.log(overNode.parent.key );
      groupCategory = overNode.key;
      groupCategoryId = overNode.allLeafChildren[0].data.category_id;
               console.log(entriesByCategories);
                        console.log(groupCategoryId);
         console.log(entriesByCategories[groupCategoryId]);
      //console.log(overNode.allLeafChildren);
      console.log(entriesByCategories[groupCategoryId].length);


      Object.entries(overNode.allLeafChildren).forEach(([key, value]) => {
      //    console.log(key +' : "/api/forecast_entries/"'+value.data["id"]);
      // console.log(entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]));
      if(entriesByCategories[groupCategoryId]){
      if(!entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]) ){
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+value.data["id"]);
            console.log("/api/forecast_entries/"+value.data["id"]);
           }

      }

               //  entries.push("/api/forecast_entries/"+value.data["id"]);
         });
        
      entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+movingNode.data["id"]);
     entries=entriesByCategories[groupCategoryId];

      groupTypeName=overNode.parent.key;
      groupTypeId="api/forecast_types/"+overNode.allLeafChildren[0].data.type_id;
      

    } else if(overNode.parent.parent!==undefined ) {
      // if over a non-group, we take the country directly
      groupCategory = overNode.data.category;
      groupCategoryId = overNode.data.category_id;
      groupTypeName=overNode.parent.parent.key;
      groupTypeId="api/forecast_types/"+overNode.data.type_id;

            Object.entries(overNode.parent.allLeafChildren).forEach(([key, value]) => {
      //    console.log(key +' : "/api/forecast_entries/"'+value.data["id"]);
      // console.log(entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]));
      if(entriesByCategories[groupCategoryId]){
      if(!entriesByCategories[groupCategoryId].includes("/api/forecast_entries/"+value.data["id"]) ){
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+value.data["id"]);
            console.log("/api/forecast_entries/"+value.data["id"]);
           }
      }


    });
            entriesByCategories[groupCategoryId].push("/api/forecast_entries/"+movingNode.data["id"]);
           entries=entriesByCategories[groupCategoryId];
          } 
    var needToChangeParent = movingNode.data.category !== groupCategory;
    // console.log(needToChangeParent);
    // console.log(groupCategory);
    // console.log(groupType);
    // console.log(movingNode.data.type);

    if (needToChangeParent && groupTypeName==movingNode.data.type) {
      var movingData = movingNode.data;
          console.log(entriesByCategories);
      //entries.push(value);
      console.log(movingData);

      movingData.category = groupCategory;

      gridRef.current.api.applyTransaction({
        update: [movingData],
      });
      gridRef.current.api.clearFocusedCell();



     putCategory(groupCategoryId,groupCategory,groupTypeId,company,entries);


    }
  };
let [id, setId] = useState();

let [revenueCategories,setRevenueCategories]=useState([]);
let [expenseCategories,setExpenseCategories]=useState([]);

let [entryByType,setEntryByType]=useState([]);

let [entriesByCategories, setEntriesByCategories]=useState([]);
let getCategories =false;
let entryToCategorize='A catégoriser';

  const [bankAccount, setBankAccount] = useState();



  const columnTypes = useMemo(() => {
    return {
      editableColumn: {
        editable: (params) => {
          return isCellEditable(params);
      }//,
      //   cellStyle: (params) => {
      //     if (isCellEditable(params)) {
      //       return { backgroundColor: 'lightBlue' };
      //     }
      //   },
       },
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {

      'red': 'data.type === "Décaissements"',
     // ||node.parent.key === "Encaissements" || node.key === "Encaissements"
      'green': 'data.type === "Encaissements" ',
      // 'green': 'data.poste === "Solde fin"',
    };
  }, []);


const getRowClass = params => {
  if(params.data  !== undefined){
        if (params.data.type === "Décaissements" ){
        return 'red';

    }
    else if(params.data.type === "Encaissements" ){
        return 'green';

    }
  }
  else if(params.node.parent.key === "Décaissements" || params.node.key === "Décaissements" ) return 'red';
  else if(params.node.parent.key === "Encaissements" || params.node.key === "Encaissements" ) return 'green';

};

  const gridOptions = {
      domLayout: 'autoHeight',
    //  rowClassRules: rowClassRules ,
     undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
      getRowClass: getRowClass,
          getRowId: params => params.data.id,
          onCellClicked: (params) =>  {
        console.log(params);

        // if (params.data != undefined) {
        //            if (
        //              params.data.poste != undefined &&
        //              params.colDef.field == "poste"
        //              &&  params.data.poste !== "TVA à reverser"
        //              &&  params.data.poste !== "Solde début"
        //              &&  params.data.poste !== "Solde fin"
        //            ) {
        //                  openModal(params);
        //                return false;
        //            }
     
        // }
        // else 
        if(params.node.group && params.node.key !="Décaissements" && params.node.key !="Encaissements" ) {
                     
                       return false;

        }
        else  if(params.colDef.field=="option")categoryEdit(params);
        else  if(params.colDef.field=="delete")deleteCategoryFromGrid(params);
      },
      onCellDoubleClicked: (params) =>  {
        console.log(params);
        setCategoryId(params.data.value);
        if (params.data != undefined) {
                   if (
                     params.data.poste != undefined ||
                     params.colDef.field !== "poste"
                     ||  params.data.poste !== "TVA à reverser"
                     || params.data.poste !== "Solde début"
                    ||  params.data.poste !== "Solde fin"
                   ) {
                    console.log("onCellDoubleClicked");
                        // openModal(params);
                       return false;
                   }
                   else return false;
     
        } else if (params.data == undefined) {
          if (params.node.level == 2) {
            console.log("2");
          } else if (params.node.level == 0) {
            console.log("0");
          } else if (params.node.level == 1) {
            console.log("1");
          }
          else if (params.data.poste !== "TVA à reverser") {
            console.log("TVA à reverser");
          }
          console.log("open");
          return false;
        }
      },
        groupDisplayType: 'custom',
  suppressRowClickSelection: true,
  groupDefaultExpanded: 0,
  rowSelection: 'multiple',
  groupSelectsChildren: true,
  animateRows: true,
  suppressAggFuncInHeader :true,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
  };

// gridOptions.getRowClass = function(params) {
//   if (params.node.group) {
//     return 'background-green';
//   }
// }
    const gridOptions2 = {
        domLayout: 'autoHeight',
      rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  console.log(params)
  }
    let aggCallCount = 0;


const myAggFunc = (params) => {
  aggCallCount++;
  let total = 0;
  let amount = 0;
     
  //console.log(params);
  for (let i = 0; i < params.values.length; i++) {
  //  console.log(i+' : ' + params.values[i]);
    amount=params.values[i];

   // console.log(amount);

    if(isNaN(amount)) amount = 0;

   if(params.rowNode.allLeafChildren[i].data.disabled===false || params.rowNode.allLeafChildren[i].data.disabled === undefined || params.rowNode.level=='0'   ){
    //console.log('ok');  
    //console.log(params); 
      total += parseFloat(amount);
  }
   else  {
   // console.log("nok");  
    //console.log(params);
   }


  }
  return total;
};

  const [rowNode, setRowNode] = useState();

function categoryEdit(params){
console.log(params.data);

setRowNode(params.data);
//rowNode.data
  setCategoryName(params.data.label);
  console.log(params.data.value);
  setCategoryId(params.data.value);
  setThisRowParams(params);
  setCheckedItems([]);
  setCategoryType(params.data.categoryType);
   categoryEditModal.onOpen();
}

function categorizeExpense(){
     setCategoryType('1');
      setCheckedItems([]);
      setCategoryName('');
     // test2('api/entries/sdfsfsf');
      categoryModal.onOpen();
}

function categorizeRevenue(){
      setCategoryType('2');
      setCheckedItems([]);
      setCategoryName('');
      categoryModal.onOpen();
}

function newExpense(){
  openNewModal(1);
}

function newRevenue() {
  openNewModal(2);
}

function openNewModal(categoryType) {
  let params=[];
  params["data"]="";
  setPhisRowParams(params);
  setSelectedCategory();

  //setVatSelected(defaultVat);
  setId();
  setCategoryType(categoryType);



  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

let todayFomated = yyyy+'-' + mm + '-' + dd ;
//let todayFomated = dd+'/' + mm + '/' + yyyy ;
console.log(today);
       setRecurring(true);
       setFixedAmount();
     //  let vat={}
  setVatSelected({"value":null});
      setRecurringInterval( {label : 'Mensuel', value: '/api/intervals/1'});
      // setStartDate(today);
      setStartDateFormated(todayFomated);
       setFinishDateFormated();
       //setFinishDate();

  modalIsOpen = true;
  entryModal.onOpen();

  // resetMonths();
  // setParams(params);
  // onOpen();
}


function openModalTest(params){
 
    showRow();
    resetMonths();
    setRow(params.data.num_line);
    console.log(params);
    setName(params.data.num);
    setMappedName(params.data.num);
    setAmount(params.data.mean);
    setMonths();
    setMonthPeriods();
    monthToShow=[];
    monthstoPeriod=[];

    let actual_month="";
    Object.entries(params.data.amount_by_month).forEach(([key, value]) => {
          actual_month=key;
            Object.entries(value).forEach(([key, value]) => {

            monthstoPeriod.push(actual_month+':'+value.period);
  
            eval("set"+actual_month+'Amount')(value.amount);

        });

      });
  if(params.data.mean<0){
  setTitle('Décaissements');
  setCategories(expenseCategories);
  setCategory("/api/forecast_categories/2");
  setSelectedCategory({ value: "/api/forecast_categories/2", label: 'Loyers' });
}
else {
  setTitle('Encaissement');
  setCategories(revenueCategories);
    setCategory("/api/forecast_categories/4");
    setSelectedCategory({ value: "/api/forecast_categories/4", label: 'Ventes' });
}
     setMonths(monthToShow);
     setMonthPeriods(monthstoPeriod);

if(params.data.ecart==0)setShowVariableAmount(0);
  let p={ value: "/api/intervals/"+params.data.interval, label: params.data.periodicity_label };
  //console.log(p);
  setPeriodicitySelected(p);
  setPeriodicity("/api/intervals/"+params.data.interval);
  setStart(params.data.next_date);
 // onOpen();
}


function pinnedRow() {
  var result = [];
//gridApi.forEachNode(printNode);
console.log("pinned");
//         gridOptions.api.forEachNode((rowNode, index) => {
//     console.log(index + 'node ' + rowNode + ' is in the grid');
// });
    // console.log( gridOptions.api.getDisplayedRowAtIndex(1) );
  // for (var i = 0; i < count; i++) {
  //   result.push({
  //   id: 'tot',
  //   name: 'tot',
  //   city:'tot',
  //   distro: 'tot',
  //   laptop: 'tot',
  //   value: 'tot',
  //   });
  // }
//console.log(rowdata);
  // rowData= params.columnApi.getRow(0);
  //  result=rowData[0];

    //  gridOptions.api.setPinnedBottomRowData(rows);
  return result;
}

  const printNode = (node, index) => {
    if (node.group) {
      console.log(index + ' -> group: ' + node.key);
    } else {
      console.log(
        index + ' -> data: ' + node.data.post 
      );
    }
  };

    const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);


//<Redirect push to="/report/start" />;

  function test(params){

 //let a = params.api.getRenderedNodes();

// let bottom=[];
// let top=[];
// bottom.push(a[0].data);
// top.push(a[1].data);

// params.api.setPinnedTopRowData(top);
// params.api.setPinnedBottomRowData(bottom);
console.log(startRow);
// params.api.setPinnedBottomRowData(startRow);
// params.api.setPinnedTopRowData(endRow);

  }
    const [reportId, setReportId] = useState([]);
    
  const onGridReady = (params) => {
      // console.log("tiop ");
      //  var rowNode = params.api.getRowNode(0);
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        let revenueCategoriesTemp=[];
        let expenseCategoriesTemp=[];
        let entriesByCategoriesTemp=[];

        if (expenseCategories.length==0) {
        //cashFlowApi.getAccount('15121')
        getCategories = cashFlowApi.getCategories();       
        //let products = paymentApi.getProducts();   
        let categoryNameTemp='';
        getCategories.then( (getCategories) =>{
          setCompany(getCategories["hydra:member"][0]["company"]["@id"]);

          Object.entries(getCategories["hydra:member"]).forEach(([key, value]) => {
         // console.log(value);
          categoryNameTemp=value["@id"] ;
           entriesByCategoriesTemp[categoryNameTemp]=[];
            Object.entries(value.entry).forEach(([name, value]) => {
               console.log(value);
             // console.log(categoryNameTemp);
               //  value.push({total:value.entry.length});
                   entriesByCategoriesTemp[categoryNameTemp].push(value);
                      });
                      //  entriesByCategoriesTemp[value["@id"]]=value.entry;
            if(value["name"]!==entryToCategorize){
              let total=value.entry.length;
            if(value["type"]=="/api/forecast_types/1")expenseCategoriesTemp.push({category:"Décaissements",value:value["@id"], id:value["@id"],label:value.name,option:'gérer',categoryType:value["type"],total:total,disabled:false});
            else                                      revenueCategoriesTemp.push({category:"Encaissements",value:value["@id"], id:value["@id"],label:value.name,option:'gérer',categoryType:value["type"],total:total,disabled:false});
}

            //setCategory(value["@id"]);

         });

          setEntriesByCategories(entriesByCategoriesTemp);
          console.log(entriesByCategoriesTemp);

          revenueCategoriesTemp.sort((a, b) => a.label.localeCompare(b.label));
          setRevenueCategories(revenueCategoriesTemp);

          expenseCategoriesTemp.sort((a, b) => a.label.localeCompare(b.label));
          setExpenseCategories(expenseCategoriesTemp);
          setRowData(revenueCategoriesTemp.concat(expenseCategoriesTemp));
          console.log("length2"+categories.length);
          updateData();
        });
      } 

     
       // let companies= cashFlowApi.getCompanies(); 
       //    companies.then((companies)=>{

       //    setCompany(companies["hydra:member"][0]["@id"]);
       //    setReportId(companies["hydra:member"][0]["report"][0]["@id"]);
       //    console.log(companies["hydra:member"][0]["report"]);
       //    console.log(companies["hydra:member"][0]["report"][0]);

       //    let uuid=companies["hydra:member"][0]["report"][0].split("/");
       //    console.log("reports" +uuid[3]);
       //    let report = cashFlowApi.getReport(uuid[3]); 
    
       //    report.then( (report) => updateData(report,params)   
       //                )
       //    .then(testPinned  );
       //  });

      const testPinned= ()=> {
            //  console.log("pinned Test!!!")

          test(params);
          //console.log("pinned");
      }
  };

  let monthsColumsArray=[];

  const updateData= () =>{

      let col=[];
      let colNum=0;
      let entryToCategorizeExpense=[];
      let entryToCategorizeRevenue=[];
     // col.push({ headerName: 'type', field: 'type', rowGroup: true ,   hide: true}); 
      col.push({ headerName: 'category', field: 'category', rowGroup: true ,   hide: true}); 

      col.push({
      headerName: "Catégories",
      field: "label",
      showRowGroup: true,
      animateRows:true,
   

    //         cellRendererSelector: (params) => {
    //     const categoryDetails = {
    //       component:CategoryRenderer,
    //     };

  

    //     return categoryDetails;
    // },
      cellRendererParams: {
      suppressCount: false, // turn off the row count (in order to skip default stack counting)
     // innerRenderer: customInnerRenderer, //our inner renderer in charge of child nodes counting
    //editable: true,
      innerRenderer:CategoryCellRenderer
    },

      cellRenderer: "agGroupCellRenderer",
          sort: 'asc',  // optional, allows grid column to be sorted on init
          comparator: customComparator,

      //rowDrag: rowDrag,  

 // innerRenderer: 'customInnerRenderer',
    minWidth: 300,

        editable:  (params) => {
          return isCellEditable(params);
        }
    //editable: true,
  });

  //col.push({  headerName: 'Group Renderer C',      field: 'poste',      minWidth: 240,      showRowGroup: true,      cellRenderer: 'agGroupCellRenderer',      cellRendererParams: {        suppressCount: true,              innerRenderer: SimpleCellRenderer,        suppressDoubleClickExpand: true,        suppressEnterExpand: true      }});
 
      col.push({ headerName: '', field: 'option', width:50,
              cellRendererParams: {
              
      suppressCount: false, // turn off the row count (in order to skip default stack counting)
     // innerRenderer: customInnerRenderer, //our inner renderer in charge of child nodes counting
    //editable: true,
      innerRenderer:ExchangeCellRenderer
    },
        cellRenderer: ExchangeCellRenderer ,  

         hide: false}); 

            col.push({ headerName: '', field: 'delete', 
              width:50,
              cellRendererParams: {
                
      suppressCount: false, // turn off the row count (in order to skip default stack counting)
     // innerRenderer: customInnerRenderer, //our inner renderer in charge of child nodes counting
    //editable: true,
      innerRenderer:DeleteCellRenderer,
       onClick: deleteCategoryFromGrid()
    },
        cellRenderer: DeleteCellRenderer ,    
         hide: false}); 
      //col.push({ headerName: 'periods', field: 'periods',  hide: true}); 
    col.push({ headerName: 'disabled', field: 'disabled',  hide: true}); 
    col.push({ headerName: 'categoryType', field: 'type',  hide: true}); 
    col.push({ headerName: 'total', field: 'total',  hide: true,editable:false}); 
    col.push({ headerName: 'îd', field: 'id',  hide: true}); 
   //   col.push({headerName: 'Categorie', field: 'label', sortable: true ,  width: 180}); 
      //
      let headername='';
          setColumns(col);






          //  setRowData2(row_col2);
    
  }



// useEffect(() => {

//   let  row_col=[]; 

//     Object.entries(revenueCategories).forEach(([key, value]) => {
//     //  let id=value["@id"].split("/")[3];
//              console.log(value);
//              row_col.push(value); 
//               });

//     Object.entries(expenseCategories).forEasetch(([key, value]) => {
//     //  let id=value["@id"].split("/")[3];
//              console.log(value);
//              row_col.push(value); 
//               });

  
//     setRowData(row_col);


// },[revenueCategories,expenseCategories]);

 function customComparator(valueA, valueB) {
  const str = valueA || '';
const str2 = valueB || '';

    return str.toLowerCase().localeCompare(str2.toLowerCase());
  }

function SortByName(x, y) {
  console.log(x.label + ":" + y.label);
  return x.label == y.label ? 0 : x.label > y.label ? 1 : -1;
}

function sortByNameAsc() {
  gridColumnApi.applyColumnState({
    state: [{ colId: 'label', sort: 'asc' }],
    defaultState: { sort: null },
  });
}

function addCategory(uuid,name,type){
  console.log(categoryType);

  let previousCategories=[];

  previousCategories=entriesByCategories
  previousCategories[uuid]=[];
  previousCategories.sort((a, b) => a.label.localeCompare(b.label));
  setEntriesByCategories(previousCategories);

  let  category='Décaissements';
  if(categoryType=="/api/forecast_types/2")category='Encaissements';

  let newCategorie={category:category, id:uuid, value:uuid,label:name,option:'gérer',categoryType:type,total:0,disabled:false};

  if(type=="/api/forecast_types/1"){
    let previousExpenseCategories=[];

    previousExpenseCategories=expenseCategories;
    previousExpenseCategories.push(newCategorie);
    previousExpenseCategories.sort((a, b) => a.label.localeCompare(b.label));
    setExpenseCategories(previousExpenseCategories);
  } 
  else  {
    let previousRevenueCategories=[];

    previousRevenueCategories=revenueCategories;
    previousRevenueCategories.push(newCategorie);
    previousRevenueCategories.sort((a, b) => a.label.localeCompare(b.label));
    setRevenueCategories(previousRevenueCategories);
  }

  let newRow=[];
  newRow.push(newCategorie);

  gridApi.applyTransaction({add:newRow});
  sortByNameAsc();

 // RefreshCategoryColumn();
}

const   onCellValueChanged= (params)=>{
        //console.log(params);
        //RefreshCategoryColumn();
        console.log('modalIsOpen :' + modalIsOpen);
        //modalIsOpen= entryModal.isOpen;
        if (!modalIsOpen) {
              console.log('modalIsOpen :' + modalIsOpen);
          if (
            isNaN(params.value) &&
            params.colDef.field != "poste" &&
            params.colDef.field != "periods"
          )console.log("params"+ params);
            //alert("saisir un nombre");
          if (params.colDef.field == "id" || params.colDef.field == "periods") {
            console.log("no change");
          } else {
            console.log(params);
            console.log(categoryId);
            // or params.value;
            // 
            if(!controlCategoryName(params.data.label,params.data.categoryType)){ alert("ce nom est déjà utilisé"); gridApi.undoCellEditing();return'';}

            else sendCategory(params.data.label);
          }
        }

   
       }

  // function addCategory(params){

  //   if(params.rowPinned!==undefined)return '';
  //   if(params.rowPinned=='bottom')return '';
  //   if(params.rowPinned=='top')return '';
  //   if(params.type=="cellValueChanged" && params.oldValue===undefined)return '';
  //       if(params.node.id=="0")return '';
  //   console.log(params);


  //   //if(params.type=="cellValueChanged" )return '';
  //   let showVariableAmount=0;
 
  //   let entryPeriods=[];

  //   let amount="0";
  //   let periodId='';
  //   let monthPeriod='';
  //   let periodicity='';
  //   /* variables amounts into DB */
  //   let name=params.data.poste;
  //   let type_id="api/forecast_types/"+params.data.type_id;
  //   //console.log("category :" +params.data.category_id);
  //  // let categoryId="api/forecast_categories/"+params.data.category_id;
  //   let categoryId=params.data.category_id;  
  //   let id=params.data.id;
  //   let recurring=true;
  //   let startDate='';
  //   let finishDate='';
  //   let vatSelected='';
  //   let    vatSelectedLabel='';

  //     if (forecastEntries[params.data.id]){
  //        recurring=forecastEntries[params.data.id].recurring;
  //        // setFixedAmount(forecastEntries[params.data.id].amount);
  //        // setRecurringInterval(forecastEntries[params.data.id].recurringInterval);
  //         startDate=forecastEntries[params.data.id].startDate;
  //         finishDate=forecastEntries[params.data.id].finishDate;

  //         vatSelected=forecastEntries[params.data.id].vat.value;
  //         vatSelectedLabel=forecastEntries[params.data.id].vat.label;
  //       }


  //   let label='';
  //   let bankId='';
  //   console.log(params);
  //   console.log(id);
  //   let newEntry= false;

  //   if(id==undefined || id =='')newEntry=true;
  //   //console.log("id :"+id);
  //   //if(params.data.periods!=undefined) { 
  //    //console.log(params.data.periods[params.colDef.field]);
  //    let amountPeriod=0;
  //    let month = null;
  //    let months=[];

  //     for (  month in params.data) {

  //     if (
  //       month == "disabled" ||
  //       month == "category" ||
  //       month == "poste" ||
  //       month == "type_id" ||
  //       month == "type" ||
  //       month == "category_id"
  //     ) {
  //       break;
  //     } else if (month == "id") {
  //       break;
  //     } else if (params.rowPinned !== undefined) break;
  //     //console.log(params);
  //       // example :  month = 200301
  //       monthPeriod=month.substring(4);
  //       months.push(month);
  //       amountPeriod=params.data[month].toString();
  //       periodId=null;

  //       if(newEntry==true){
  //         startDate=month.substring(0,4)+"-"+monthPeriod+"-01";
  //         amount=amountPeriod;

  //       }

  //       //console.log(month);
  //       if(params.data.periods!=undefined) {
  //         periodId = params.data.periods[month];
  //         console.log(params.data.periods);

  //       } 
   
  //       //console.log(" periodid "+periodId);
  //     //  console.log("amount "+ amountPeriod);
  //       if(periodId!=undefined && periodId!=null && amountPeriod !="0" ){

  //         entryPeriods.push({
  //             id:periodId,
  //             amount:amountPeriod,
  //             period:monthPeriod
  //         });
  //       }
  //       else if(!isNaN(amountPeriod)  && amountPeriod !="0" ){
  //         entryPeriods.push({
  //             //id:null,
  //             amount:amountPeriod,
  //             period:monthPeriod,
  //             recurringInterval:"/api/intervals/9"
  //         });
  //       }
  //       //console.log(month +";"+params.data[month]+":"+params.data.periods[month]);
  //     }
  //    // console.log(entryPeriods);
  //   //}
  //     let response='';
  //     if(newEntry==true && entryPeriods.length>0 && params.data.id !== undefined  ){
  //         periodicity="/api/intervals/10";
  //       //console.log("reports" +uuid[3]);

  //       // response = putEntry(
  //       //   params.data.type_id,
  //       //   params.data.category_id,
  //       //   params.data,
  //       //   params.data.periods,
  //       //   params.data.poste
  //       // ); 

  //       // response =  cashFlowApi.postEntry(reportId,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
  //        //  report.then( (report) => updateData(report,params))
  //       response.then( (response) => {
  //         // console.log(response.data);

  //         let uuid=response.data["@id"].split("/");
  //         // console.log("reports" +uuid[3]);
  //         //  console.log("id"+response.data["@id"]);
  //         //console.log(gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex));
  //            var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
  //             rowNode.setDataValue('id', uuid[3]);

  //               // console.log(response.data["entryPeriods"]);
  //               //         console.log(response.data["entryPeriods"][0]);
  //               //   console.log(response.data["entryPeriods"][0]["@id"]);
  //             //let a= response.data["entryPeriods"][0]["@id"].split("/");
  //                      // console.log(a[3]);
  //                   setForecastEntries(response);

  //               let vatRate= response.vat["rate"];
  //             agGridAddPeriods(response,rowNode,type_id,params,false,vatRate);


  //            // updateSummaries(params,params.colDef.field,amount);
 
  //       } 
  //       );         

  //     }
  //   else if(newEntry==false && params.data.id !== undefined){

  

  //         //if (entryPeriods.length>1) 
  //         periodicity = "/api/intervals/10";
  //       //  else periodicity=
  //       // console.log("newEntry false :  "+ amount);
  //       //response = cashFlowApi.putEntry(id,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
  //     //  let entryPeriods = getEntryPeriods(params.data);
  //     console.log(params);
  //           let entryId=params.data.id;
  //           let month=params.colDef.field;
  //           let amount=params.newValue;
  //           let rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
  //           let typeId=params.data.type_id;
  //          // let categoryId=params.data.category_id;
  //           let category=params.data.category;
  //           let poste=params.data.poste;
  //             agGridAddPeriodsSingleCell(month,amount,rowNode,typeId,params);

  //             response = putEntry(
  //               entryId,
  //               amount,
  //               typeId,
  //               categoryId,
  //               entryPeriods,
  //               //params.data.periods,
  //               poste,
  //               periodicity,
  //               "",
  //               params,
  //               recurring,
  //               startDate,
  //               finishDate,
  //               false,
  //               categoryId,
  //               category,
  //               false,
  //               vatSelected,
  //               vatSelectedLabel,
  //               disabled
  //             ); 

      
      
  //     //   response.then( (response) => {
  //     //     var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
  //     //     agGridAddPeriods(response,rowNode);
  //     //  //   updateSummaries(params,params.colDef.field,amount);
  //     //       });
  //   }

  //   // else if(newEntry==false){
  //   //       periodicity="/api/intervals/10";
  //   //   response = cashFlowApi.putEntry(id,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId);
  //   //   response.then( (response) => {
  //   //       var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
  //   //       agGridAddPeriods(response,rowNode);
      
  //   //         });
  //   // }


  //     // if(typeof month !== null && params.data.id !== undefined ){
  //     //   let oldValue=0;
  //     //   if(params.oldValue!==undefined)oldValue=params.oldValue
  //     //   let newAmount=params.newValue-oldValue;
  //     //   if(params.data.type_id=="1")newAmount=-newAmount;
  //     //   updateSummaries(monthsColums,params.colDef.field,newAmount);
  //     // }

  //     if(showVariableAmount){
  //    //tricks for api
  //     newPeriodicity='/api/intervals/10';
  //     //console.log(monthPeriods );
  //     monthPeriods.forEach((key) => {
  //       /* key = 200.49:2022-12-01 => amount, last date, after previous month */
  //      // console.log(key);
  //       key=key.split(':');
  //       newAmount=eval(key[0]+'Amount');
  //       if(newAmount<0){
  //           type="api/forecast_types/1";
  //           newAmount=-newAmount;
  //       }
  //       else {
  //           type="api/forecast_types/2";
  //       }
  //       newStartDate=key[1];
  //       //let periodicity=
  //       //console.log("value "+key[0] +' '+eval(key[0]+'Amount') +',date :' +key[0],);
  //       entryPeriods.push({amount:newAmount.toString(),recurringInterval:newPeriodicity,period:key[1]});
  //     // cashFlowApi.postEntry(report,type,newAmount.toString(),name,category,newPeriodicity,recurring,newStartDate,finish);

  //       });
  //  // console.log();
  // }
  // }

function putEntry(
  id,
  amount,
  typeId,
  category,
  entryPeriods,
  // periods,
  name,
  periodicity,
  periodicityLabel,
  params,
  recurring,
  startDate,
  finishDate,
  isOpen,
  categoryIdFromModal,
  categoryNameFromModal,
  fromModal,
  vatSelected,
  vatSelectedLabel,
  disabled
) {
  let showVariableAmount = 0;
  //let entryPeriods = [];
  modalIsOpen = isOpen;
  /* variables amounts into DB */

  let type_id = "api/forecast_types/" + typeId;
  //console.log("category :" +params.data.category_id);
  //let category_id = "api/forecast_categories/" + categoryId;
  //let category_id = category;

  // let startDate = "";
  // let finishDate = "";
  let label = "";
  let bankId = "";

  let newEntry = false;
  let months = entryPeriods;
  console.log("modalIsOpen :" + modalIsOpen);
  //return "";
  if (id == undefined || id == "") newEntry = true;

  let mappedName = null;
  if (params.data.num !== undefined) mappedName = params.data.num;
  console.log("params.data.id " + params.data.id);
  console.log(params);
  //}
  let response = "";
  if ( newEntry == true
    // &&    params.data.id !== undefined
  ) {
    if (entryPeriods.length < 1) entryPeriods = [];
    else periodicity = "/api/intervals/10";
    //console.log("reports" +uuid[3]);
    response = cashFlowApi.postEntry(
      reportId,
      type_id,
      amount,
      name,
      category,
      periodicity,
      recurring,
      startDate,
      finishDate,
      entryPeriods,
      mappedName,
      bankId,
      vatSelected
    );
    response.then((response) => {
      console.log(response);
      entryModal.onClose();
      let type='Décaissements';
      if (typeId == "2")  type = "Encaissements";
 
          let row = [
            {
              category: categoryNameFromModal,
              category_id: category,
              type: type,
              type_id: typeId,
              poste: name,
            },
          ];
     // console.log(row);
      let a = gridRef.current.api.applyTransaction({ add: row });
//console.log(a["add"]);
  //    console.log(a["add"][0]);
      var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
      console.log(rowNode);
      let uuid = response["@id"].split("/");
      rowNode.setDataValue("id", uuid[3]);
      rowNode.setDataValue("poste", response["name"]);
      setForecastEntries(response);
      let vatRate= response.vat["rate"];
      agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate);

    
//         forecastEntries[uuid[3]] = {

//               startDate: startDate,
//               finishDate: finishDate,
//               recurring: recurring,
//               amount:amount,
//               recurringInterval: {label :periodicityLabel, value: periodicity}
//         };

// console.log(forecastEntries);


    // setTimeout(() => {
    //   modalIsOpen = false;
    // }, 1000);
       // updateSummaries(params,params.colDef.field,amount);
    });


    // } else if (newEntry == false && params.data.id !== undefined) {
    // periodicity = "/api/intervals/10";
    // console.log("newEntry false :  " + amount);

    // response.then((response) => {
    //   var rowNode = gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);
    //agGridAddPeriods(response, rowNode);
    //   //   updateSummaries(params,params.colDef.field,amount);
    // });
  } else if (newEntry == false) {
    if (entryPeriods.length < 1){
            entryPeriods = [];
          //  rowNode.setDataValue("periods", null);
    } 
    else periodicity = "/api/intervals/10";

    response = cashFlowApi.putEntry(
      id,
      type_id,
      amount,
      name,
      category,
      periodicity,
      recurring,
      startDate,
      finishDate,
      entryPeriods,
      label,
      bankId,
      vatSelected,
      disabled
    );
    entryModal.onClose();
    console.log(params);
    response.then((response) => {
      console.log(response);
      let vatRate= response.vat["rate"];
       var rowNode =   gridRef.current.api.getDisplayedRowAtIndex(params.rowIndex);

            //let a = gridRef.current.api.applyTransaction({ add: row });
//console.log(a["add"]);
  //    console.log(a["add"][0]);
    //  var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
  if(response.disabled!=true) {
 const itemsToUpdate = [];
  
      const data = rowNode.data;
     data.category= categoryNameFromModal;
     data.category_id=category;
     data.poste=response.name;
     //data.disabled=response.disabled;
         if (entryPeriods.length < 1){
            data.periods=null
          //  rowNode.setDataValue("periods", null);
    } 
      itemsToUpdate.push(data);
  const res = gridRef.current.api.applyTransaction({ update: itemsToUpdate });

  }

  setForecastEntries(response)
  //  printResult(res);
           // let a = gridRef.current.api.applyTransaction({ add: row });
//console.log(a["add"]);
  //    console.log(a["add"][0]);
    //  var rowNode = gridRef.current.api.getRowNode(a["add"][0].id);
   //   console.log(res);

          // rowNode.setDataValue("category", categoryNameFromModal);
          // rowNode.setDataValue("poste", response["name"]);
    
    
          // rowNode.setDataValue("category_id", categoryIdFromModal);
       
     // if(params.oldValue!==undefined) 
        agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate);
      if(response.disabled==true){


    
  const res = gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }

      console.log(forecastEntries);
         // modalIsOpen = false;
    });

  }
}


function setForecastEntries(response){
  //console.log(response);
  let uuid = response["@id"].split("/");

  let vat = null;
  if(response["vat"] !==null){
    vat={label : response.vat.rate, value: response.vat["@id"]};
    //console.log(vat);
  }

    forecastEntries[uuid[3]] = {
        startDate: response.startDate,
        finishDate: response.finishDate,
        recurring: response.recurring,
        amount:response.amount,
        vat:vat,
        disabled:response.disabled,
        recurringInterval: {label :response.recurringInterval["label"], value: response.recurringInterval["@id"]}
        };
}


function getEntryPeriods(months) {
    let entryPeriods = [];
    let amountPeriod = 0;
    let month = null;
    //let months = [];
    let periodId = "";
    let monthPeriod = "";

  for (month in months) {
    if (
      month == "category" ||
      month == "poste" ||
      month == "type_id" ||
      month == "type" ||
      month == "disabled" ||
      month == "category_id"
    ) {
      break;
    } else if (month == "id") {
      break;
    } else if (params.rowPinned !== undefined) break;
    //console.log(params);
    // example :  month = 200301
    monthPeriod = month.substring(4);
   // months.push(month);
   // amountPeriod = dataMonths[month];
    periodId = null;

    if (newEntry == true) {
      startDate = month.substring(0, 4) + "-" + monthPeriod + "-01";
      amount = amountPeriod;
    }

    //console.log(month);
    if (periods != undefined) periodId = periods[month];

    //console.log(" periodid "+periodId);
    //  console.log("amount "+ amountPeriod);
    if (periodId != undefined && periodId != null && amountPeriod != "0") {
      entryPeriods.push({
        id: periodId,
        amount: amountPeriod,
        period: monthPeriod,
      });
    } else if (!isNaN(amountPeriod) && amountPeriod != "0") {
      entryPeriods.push({
        //id:null,
        amount: amountPeriod,
        period: monthPeriod,
        recurringInterval: "/api/intervals/9",
      });
    }
    //console.log(month +";"+params.data[month]+":"+params.data.periods[month]);
  }
  return entryPeriods;
}

  function updateSummaries(months,col,amount,vatRate){
    var topPinnedRow= gridRef.current.api.getPinnedTopRow(0);
    var bottomPinnedRow= gridRef.current.api.getPinnedBottomRow(0);
    let vatCol='';
    let vatAmount=0;

    // let vatRow = gridRef.current.api.getRowNode(0);

    // const year = col.slice(0, 4);
    // let monthPeriod=col.slice(4);

    // if(monthPeriod<12) vatCol=eval(col)+1;
    // else {
    //     vatCol=eval(year)+1+'01';
    //    }


     
    // if(vatRate!=0 && vatRow.data[vatCol]!==undefined){
    //   vatAmount=amount-(amount/(1+(vatRate/100)));
    //   vatAmount=Math.round(vatAmount);
    //   vatRow.setDataValue(vatCol, vatRow.data[vatCol] +vatAmount);
    //  } 
console.log(amount);
     let vatResult=updateVat(col,amount,vatRate);
     vatCol=vatResult[1];
     vatAmount=vatResult[0];
  //  months.forEach
    for (const [key, month] of Object.entries(months)) {
     // console.log(key, month,col,amount);
      let vatAmountMonthStart=0;
      let vatAmountMonthFinish=0;
      if(vatCol<month)vatAmountMonthStart=vatAmount;
      if(vatCol<=month) vatAmountMonthFinish=vatAmount;

     if(col<month) topPinnedRow.setDataValue(month, topPinnedRow.data[month]+ amount -vatAmountMonthStart);
     if(col<=month) bottomPinnedRow.setDataValue(month, bottomPinnedRow.data[month]+ amount -vatAmountMonthFinish);
    }
  }

  function updateVat(col,amount,vatRate){

    let vatRow = gridRef.current.api.getRowNode(0);

    //console.log(col);
    const year = col.slice(0, 4);
    let monthPeriod=col.slice(4);
    let vatCol='';
    //console.log(year +' ' +monthPeriod);
    if(monthPeriod<12) vatCol=eval(col)+1;
    else {
        vatCol=eval(year)+1+'01';
       }
      console.log(vatCol);
    let vatAmount=0;

    //console.log(vatRow.data[vatCol]);
      
    if(vatRate!=0 && vatRow.data[vatCol]!==undefined){
       //vatAmount=Math.round(amount*vatRate/100);
       console.log(amount +':'+vatRate);
      vatAmount=amount-(amount/(1+(vatRate/100)));
      vatAmount=Math.round(vatAmount);
      console.log(vatAmount);
      console.log(vatRow.data[vatCol]);
      vatRow.setDataValue(vatCol, vatRow.data[vatCol] +vatAmount);
     } 

     return [vatAmount,vatCol];
  }


  function agGridAddPeriodsSingleCellOldVersion(response, rowNode,typeId,params,fromModal) {
      for (const [month, value] of Object.entries(dataMonth)) {
      {
        for (const data of Object.entries(value)) {
          //  let periodId=value["@id"].split("/");
          //  var c = value.period;
          //  p.push([year+c,periodId[3]]);
          //   if(c==12)year++;

          let amount=data[1];
           console.log(monthsColums);

            if(!monthsColums.includes(month))       continue;
   
                    console.log(month, data[0], data[1]);
          if (data[0] == "id") p[month] = amount;
          // p.push(month : data[1]);
          else  if (data[0] == "amount") {
            console.log(month, data[0], data[1]);
            rowNode.setDataValue(month, data[1]);
            let newAmount=0;
           // if(singleCell){
                  if(month==params.colDef.field){
                    
                    newAmount=amount-oldValue;
                      
                    //  if(params.data.type_id=="1")newAmount=-newAmount;
                  if(typeId==1 || typeId=="Décaissement"){
                      newAmount=-newAmount;
                      console.log(newAmount);
                  }
                  // console.log(newAmount);
                  // if(newAmount!=0)
                  let vatRate=0;
                  updateSummaries(monthsColums,month,newAmount,vatRate);
                }
           // else {newAmount=0}
            //}
            // else {
            //     newAmount=amount;
            //     if(typeId==1 || typeId=="Décaissement"){
            //     newAmount=-amount;
            //     console.log(newAmount);
            //   }
            //   console.log(newAmount);
            //  // if(newAmount!=0 && fromModal)updateSummaries(monthsColums,month,newAmount);
            //   if(fromModal)updateSummaries(monthsColums,month,newAmount);

            // }

          }
        }
 } 
        // let oldValue = 0;
        // if (params.oldValue !== undefined) oldValue = params.oldValue;
        // let newAmount = params.newValue - oldValue;
        // if (params.data.type_id == "1") newAmount = -newAmount;

        //           if (col < month)
        //             rowNode.setDataValue(
        //               month,
        //               topPinnedRow.data[month] + amount
        //             );
        //           if (col <= month)
        //             bottomPinnedRow.setDataValue(
        //               month,
        //               bottomPinnedRow.data[month] + amount
        //             );
        // updateSummaries(months, params.colDef.field, newAmount);
      }
  }

  function agGridAddPeriodsSingleCell(month,amount,rowNode,typeId,params) {
     let oldValue=0;
     console.log(params);
      if(params.oldValue!==undefined )oldValue=params.oldValue;
    //console.log(month, data[0], data[1]);
    //rowNode.setDataValue(month, amount);
    let  newAmount=amount-oldValue;
         console.log(newAmount +':' +oldValue + ':'+month);     
     if(typeId==1 || typeId=="Décaissement"){
              newAmount=-newAmount;
              console.log(newAmount);
          }

      let vatRate=forecastEntries[params.data.id].vat.label;
      updateSummaries(monthsColums,month,newAmount,vatRate);
     //   rowNode.setDataValue("periods", p);
  }

  function agGridAddPeriods(response, rowNode,typeId,params,fromModal,vatRate) {
    
    let periods = [];

    let obj = "";
    let p = [];
    //let year="2023";
    let dataMonth = null;
    console.log(response);
    console.log(rowNode);
    console.log(params);
    modalIsOpen = true;
   // return '';
    //rowNode = gridRef.current.api.getDisplayedRowAtIndex(rowNode.rowIndex);
     console.log(typeId);

    let oldValue=0;
    let singleCell=false;
    if(params.oldValue!==undefined || !fromModal){
      if(params.oldValue!==undefined)oldValue=params.oldValue;
      //console.log(params.oldValue);
      singleCell=true;
    }
    if (response.data !== undefined) dataMonth = response["test"][0];
    else dataMonth = response["test"][0];

    //monthsColums;
            if(!singleCell){
            monthsColums.forEach( month => {
              
                let newAmount=0;
                let oldAmount= 0;
                if(params.data[month]!==undefined)oldAmount=params.data[month];
                
                if(dataMonth[month]!==undefined) newAmount= dataMonth[month]['amount'];
          
                if(params.data["disabled"]==true || response["disabled"]==true)newAmount=0;
                console.log("jk "+newAmount +'' + oldAmount);
                if(newAmount!=oldAmount){

                    if(response["disabled"]!==true)rowNode.setDataValue(month,  newAmount);
                    let amountDiff=newAmount-oldAmount;
                    if(typeId==1 || typeId=="Décaissement")amountDiff=-amountDiff;

                    updateSummaries(monthsColums,month,amountDiff,vatRate);
                  }

              });
            }
    //console.log("202305 : " + dataMonth["202305"]["amount"] + dataMonth["202305"]["id"]);
  else {
    //# only for single cell

      // obj=Object.fromEntries(p);
      //console.log(p);
     // rowNode.setDataValue("periods", p);
    }

    // let uuid = response["@id"].split("/");
    // rowNode.setDataValue("id", uuid[3]);
    // rowNode.setDataValue("poste", response["name"]);
    // console.log(rowNode);
    //console.log(rowNode.data.periods['202302']);
    // setTimeout(() => {
    //   modalIsOpen = false;
    // }, 1000);
//modalIsOpen=false;

    setTimeout(() => {
      modalIsOpen = false;
    }, 1000);
  }

function customInnerRenderer(params){
    //this verification is necessary, otherwise the grid will brake down
    //when last level without grouping will be reached (if exists)
    if (params.node.group) {
        var label = params.value ? params.value : '-';
        //console.log(params.node.childrenAfterFilter);
 // console.log(params.node.rowIndex);
  //  var rowNode = gridOptions.api.getDisplayedRowAtIndex(params.node.rowIndex);

  //         rowNode.setDataValue('202301', '1000');

  //        params.node.childrenAfterFilter.forEach( poste => {
  //         // console.log(poste.data["202301"]);

  //        });
  //        
        return    label  ;
        //return label + ' <b>(' +( params.node.childrenAfterFilter.length ) + ')' +'</b>';
    }
    else  {

return  " <span style={{display:'inline-block'}}>  <FaUniversity style={{display:'inline-block',marginRight:'10px'}} />{label}</span>";
       //return '<span><i class="material-icons">edit</i>' + label + '</span>';
     // return  label = params.value ? params.value : '-';

    }
}

  const getContextMenuItems = useCallback((params) => {

    var result=[];
    // start et end 
    if(params.node.rowPinned!=undefined) return  result; 
    // every entry 

    if(params.node.level==2)  return  result;             
    if(params.node.level==0)  {

     result.push(
      {
        // custom item
        name: 'Ajouter une  nouvelle catégorie ',
        action: () => {
           console.log("categorie");
           setCategoryType('/api/forecast_types/1');
          let type=params.node.key;

          if(type=="Encaissements"){
          // setToCategrorize(entryToCategorizeRevenue),
            setCategoryType('/api/forecast_types/2');
          }
    
        console.log(categoryType);
      setCheckedItems([]);
      setCategoryName('');
     // test2('api/entries/sdfsfsf');
      categoryNewModal.onOpen();
[]
      },
        cssClasses: ['redFont', 'bold'],
      });
    }     
   // level 1 and 0
   else if(params.node.level==1) {
      if( params.value== entryToCategorize){
          result.push(
      {
        // custom item
        name: 'non disponible',
        action: () => {
  
        },
        cssClasses: ['redFont', 'bold'],
      });
      }
      else  result.push(
      {
        // custom item
        name: 'Ajouter une dépense dans <b> ' + params.value+"</b>",
        action: () => {
          console.log(params);
          var category_id=null;
          var type_id=null;
          if(params.node.allLeafChildren[0]!=undefined){
          category_id=params.node.allLeafChildren[0].data.category_id;
          type_id=params.node.allLeafChildren[0].data.type_id;;
          }
  
          if(params.node.groupData!=undefined)console.log(params.node.groupData.poste);
          if(params.node.parent!=undefined)console.log(params.node.parent.key);
          if( params.value!= entryToCategorize){
 
          let row= [{category:params.value,category_id:category_id,type:params.node.parent.key,type_id:type_id,poste:"nouveau",id:''}];
          let a =params.api.applyTransaction({add:row});
          console.log(a);
                 console.log(a["add"][0]);
          console.log(a["add"][0]["id"]);
          let b = params.api.getRowNode(a["add"][0]["id"]);
          console.log(b)
          openModal(b);
          }
          else window.alert('Veuillez choisir une autre catégorie');
        },
        cssClasses: ['redFont', 'bold'],
      });
    }
    return result;
  }, []);


  function test2(id){
    const itemsToUpdate = [];
      console.log(gridOptions);
   //let rows= gridOptions.api.forEachNodeAfterFilterAndSort(test3);
   // console.log(rows);
   // const res = gridApi.applyTransaction({ update: itemsToUpdate });
   // printResult(res);

  }

  function applyCategory(data){

      console.log(data);
        //  console.log(data);
          let row= [];
      if(data.data!==undefined) {
        row.value="api/forecast_entries/"+data.data.id;
        row.id=data;
        console.log(row);
    
       }
       let itemsToUpdate=[];
         let rows=   Object.entries(checkedItems).forEach(([key, value]) => {
          if(row.value !== undefined) {
            
            if (value==row.value) {
              
              console.log(row.id.data+ 'bung' +value);
              const data = row.id.data;
              data.category=categoryName;
               data.category_id=categoryId;
              itemsToUpdate.push(data);
              const res = gridApi.applyTransaction({ update: itemsToUpdate });
            }
          }
//console.log(value);
                //console.log(value);
         // return value;
        });
     // let rows=[];
      // if(rowNode.data!==undefined) {
      //   console.log(rowNode.data.id + ':::'+id);
      //   rows.push(rowNode.data.id);
      // }
     // return rows;
  }

const [categoryName, setCategoryName] = useState(null);
const [categoryType, setCategoryType] = useState(null);
const [categoryId, setCategoryId] = useState(null);
const [company, setCompany] = useState(null);

const categoryNameChange= (name)=>{
  console.log("name" + name);
  setCategoryName(name);
}


const [checkedItems,setCheckedItems] = useState([]);

function entriesToTransfertChecked(value,isChecked){
      console.log(value,isChecked);
    let previousItems=[...checkedItems];

    if(isChecked==false){
      var index = previousItems.indexOf(value);
      if (index >= 0) {
        previousItems.splice( index, 1 );
        setCheckedItems( previousItems);
      }
    }
    if(isChecked==true) {
        var index = previousItems.indexOf(value);
        console.log(index);
        if (index <0)  setCheckedItems([...checkedItems, value ]);
      }
}

const [categoryToTransfertSelected,setCategoryToTransfertSelected] = useState([]);

function categoryToTransfert(value){
  //console.log(value);
  //console.log(categoryId);
  setCategoryToTransfertSelected(value);
}


useEffect(() => {
  if (categoryEditModal.isOpen) {
      console.log(checkedItems);
  }
  }, [checkedItems]);

function checkCategory(item){
  console.log(item); 
  return item[categoryId]["id"] != entry ;
}
function showCategory(value,entry) {
          console.log(value);
            return value["id"] != entry ;
}

function removeEntryFromCategorie(entry,categoryId){
  let items=[];
  console.log(entry+ ' '+categoryId);
  console.log(entriesByCategories);
 // items=[...entriesByCategories[categoryId]];
  // items=[...entriesByCategories];
  // items.forEach(showCategory);
let entries=[];
  for (const [key, value] of Object.entries(entriesByCategories)) {
    entries=[];
      value.forEach((value) => {
        console.log(entry +' '+ value["id"]);
        if(value["id"] != entry )   entries.push(value);
        else  console.log('trouvé :' +  entry +' '+ value["id"]);
          });
      items[key]=entries;
      console.log(key, value);

}

//let myNewArray = entriesByCategories.filter(checkCategory); 
//let myNewArray = items.filter(function(item){console.log(item); return item[categoryId]["id"] != entry }) 


console.log(items);
setEntriesByCategories('');
setEntriesByCategories(items);
//  const row = items.findIndex(row => row.includes(entry));
//   // var index = items.indexOf(entry);
// console.log(row);
//  items.splice( row, 1 );
//    console.log(items);
//   const col = items[row].indexOf(entry);
//   console.log(row+' '+col);
//    if (row >= 0) {
//      // items.splice( row, 1 );
//       // if(categoryType==1) setExpenseToCategorize( items);
//       //  else setRevenueToCategorize( items);
//     }
  }

// function removeItemFromCategorie(value,categoryType){
//   let items=[];
//   console.log(value+ ' '+categoryType);
//   if(categoryType==1) items=[...expenseToCategorize];
//   else items=[...revenueToCategorize];
//   var index = items.indexOf(value);

//   const row = items.findIndex(row => row.includes(value));
//   const col = items[row].indexOf(value);
//   console.log(row+' '+col);
//    if (row >= 0) {
//       items.splice( row, 1 );
//       if(categoryType==1) setExpenseToCategorize( items);
//        else setRevenueToCategorize( items);
//     }
//   }
  
function switchItemCategory(value,categoryType){
  let items=[];
  console.log(value+ ' '+categoryType);
  if(categoryType==1) items=[...expenseToCategorize];
  else items=[...revenueToCategorize];
  var index = items.indexOf(value);

  const row = items.findIndex(row => row.includes(value));
  const col = items[row].indexOf(value);
  console.log(row+' '+col);
   if (row >= 0) {
      items.splice( row, 1 );
      if(categoryType==1) setExpenseToCategorize( items);
       else setRevenueToCategorize( items);
    }
  }

  function putCategory(id,categoryName,categoryTypeId,company,entries){

      let result= cashFlowApi.putCategory(id,categoryName,categoryTypeId,entries);
        result.then( (categorie) =>{
        console.log(categorie);
        console.log(categorie["@id"]);
       // category_id=categorie["@id"];
       // setCategoryId(category_id);
        categoryModal.onClose();
        });
  }

  function onPostCategory(categoryName){

          let b=controlCategoryName(categoryName,categoryType);
      console.log(b);
        if(!b) return alert("existe déjà");

      let result= cashFlowApi.postCategory(categoryName,categoryType,company,'');
        result.then( (categorie) =>{

          addCategory(categorie["@id"],categoryName,categoryType);

          categoryNewModal.onClose();
        });
  }

function onDeletedCategory(){ 

        let id=categoryId.split("/")[3];

        let result= cashFlowApi.deleteCategory(id);
              result.then( (categorie) =>{
              //console.log(categorie);
              //console.log(categorie["@id"]);
              const res = gridRef.current.api.applyTransaction({ remove: [rowNode] });
               categoryEditModal.onClose();
      });
}

function deleteCategoryFromGrid(params){ 
  console.log(params);
    if(params!==undefined){
              let categoryIdFromGrid= params.data.id.split("/")[3];

        let result= cashFlowApi.deleteCategory(categoryIdFromGrid);
              result.then( (categorie) =>{
              //console.log(categorie);
              //console.log(categorie["@id"]);
              const res = gridRef.current.api.applyTransaction({ remove: [params.data] });
               categoryEditModal.onClose();
      });
    }
}

function RefreshCategoryColumn(){

     let col=["category","label","option"];
    var params = {
      // force: 1,
      // suppressFlash: 0,
      columns: [col],
    };
    console.log("refresh 2");
    gridApi.redrawRows(params);
}



  function TotalEntriesChange(category,value){

      let data=category.data;
      data.total=data.total+value;
     
      let categoryToUpdate= [];
       categoryToUpdate.push(data);

       const res = gridApi.applyTransaction({ update: categoryToUpdate });
  }

  function onTransfertCategory(){
     //   console.log(value)
        //var category_id=value.label;
        let entries = [];
        let newEntries = [];
       // let categoryTypeId = "api/forecast_types/"+categoryType;
        let categoryTypeId = categoryType;
        let type='';
        //let categoryName=value.label;
        console.log(categoryName +' '+categoryId +' '+categoryToTransfertSelected["value"]);
        console.log(entriesByCategories);

        if (categoryName=="") return;
        if (categoryType == "2") type = "Encaissements";
        else  type = "Décaissements";

      // if (entryByType[categoryType][categoryName] === undefined) entryByType[categoryType][categoryName]=[];
      
      //       entryByRevenueCategories[categoryName];
     
      //         entryByType[categoryType][categoryName].forEach((value) => {
      //           entries.push(value);
      //         });
      //       }else {
      //         entryByType[categoryType][categoryName]=[];
      //       }
      let newEntriesByCategories=[];

       if (entriesByCategories[categoryToTransfertSelected["value"]] !== undefined) {
          entriesByCategories[categoryToTransfertSelected["value"]].forEach((value) => {
               console.log(value);
               newEntriesByCategories.push(value["@id"]);
              });
       }
       let previousEntriesByCategories=entriesByCategories;

       let toCategoryTransfert = gridRef.current.api.getRowNode(categoryToTransfertSelected["value"]);
        console.log(toCategoryTransfert);
        TotalEntriesChange(toCategoryTransfert,checkedItems.length);

        let fromCategoryTransfert = gridRef.current.api.getRowNode(categoryId);
        console.log(fromCategoryTransfert);
          TotalEntriesChange(fromCategoryTransfert,-checkedItems.length);

        RefreshCategoryColumn();




        if(checkedItems.length>0) {
          //console.log(checkedItems);
            Object.entries(checkedItems).forEach(([key, value]) => {
            console.log(key + " " + value + " " + categoryTypeId);
           // removeEntryFromCategorie(value,categoryId);





           // entries.push(value);
               // newEntries.push(value);
                      newEntriesByCategories.push(value);
           // newEntriesByCategories.push("/api/forecast_entries/"+value);
            let transferedEntry=previousEntriesByCategories[categoryId].filter(function(item){ return item["@id"] == value });
            console.log(transferedEntry);
 
            previousEntriesByCategories[categoryToTransfertSelected["value"]].push(transferedEntry[0]);
            let entries=[];
         previousEntriesByCategories[categoryId].forEach((entry) => {
        console.log(value +' '+ entry["id"]);
        if(entry["@id"] != value )   entries.push(entry);
        else  console.log('trouvé :' +  value +' '+ entry["id"]);
          });
      // items[key]=entries;
      // console.log(key, value);


previousEntriesByCategories[categoryId]=entries;
           // setEntriesByCategories(previousEntriesByCategories);

          //    entriesByCategories[categoryId].push("/api/forecast_entries/"+value);
            // if (categoryType == "2") entryByRevenueCategories[categoryName].push(value);
            // else entryByExpenseCategories[categoryName].push(value);
            //removeItemToCategorize(value,categoryType);
        }) 
       // console.log(newEntries);
      setEntriesByCategories(previousEntriesByCategories);

        }


        let  id = categoryId;
        console.log(categoryToTransfertSelected);
        console.log(categoryToTransfertSelected["value"]);
        console.log(categoryToTransfertSelected["label"]);

        let result= cashFlowApi.putCategory(categoryToTransfertSelected["value"],categoryToTransfertSelected["name"],categoryTypeId,company,newEntriesByCategories);
              result.then( (categorie) =>{
              console.log(categorie);
              console.log(categorie["@id"]);
              let category_id=categorie["@id"];
             // setCategoryId(category_id);
              //categoryModal.onClose();
               updateGrid(categoryId,categoryName);
                //categoryEditModal.onClose();
              });


        if(checkedItems.length<1) {
          // let row= [{category:categoryName,category_id:categoryId,type:type,type_id:categoryType,poste:'',id:''}];
          // gridApi.applyTransaction({add:row});
        }
        else{
          //  gridApi.forEachNodeAfterFilterAndSort(applyCategory);
        }
  }

  function controlCategoryName(categoryName,categoryType){
    let myArray=revenueCategories;
    if(categoryType=="/api/forecast_types/1")myArray=expenseCategories;
//     console.log(categoryType);
// console.log(myArray);
let count=0
    for (let i=0; i < myArray.length; i++) {
              console.log(myArray[i].label +' '+ categoryName )
        if (myArray[i].label === categoryName) {
   count++;
   if(count=='2' || categoryName ===entryToCategorize)return false;
           console.log(myArray[i].label +' '+ categoryName )
       // return false;
        }
    }
    //console.log(myArray);
    return true;

  }

  function sendCategory(categoryName){
     //   console.log(value)
        //var category_id=value.label;
        let entries = [];
        let newEntries = [];
        let categoryTypeId = "api/forecast_types/"+categoryType;
        let type='';
        //let categoryName=value.label;
        console.log(categoryName +' '+categoryId +' '+categoryType);
      let b=controlCategoryName(categoryName,categoryType);
      console.log(b);
        if(!b) return alert("existe déjà");
        if (categoryName=="") return;
        if (categoryType == "2") type = "Encaissements";
        else  type = "Décaissements";

      let newEntriesByCategories=[];

       if (entriesByCategories[categoryId] !== undefined) {
          entriesByCategories[categoryId].forEach((value) => {
                   console.log(value);
                   newEntriesByCategories.push(value["@id"]);
                  });
       }


       //  if(checkedItems.length>0) {
       //    //console.log(checkedItems);
       //  Object.entries(checkedItems).forEach(([key, value]) => {
       //      console.log(key + " " + value + " " + categoryType);
       //     // entries.push(value);
       //         // newEntries.push(value);
       //        entriesByCategories[categoryId].push(value);
       //      // if (categoryType == "2") entryByRevenueCategories[categoryName].push(value);
       //      // else entryByExpenseCategories[categoryName].push(value);
       //      //removeItemToCategorize(value,categoryType);
       //  }) 
       // // console.log(newEntries);


       //  }
        let  id = categoryId;
      
        let result= cashFlowApi.putCategory(categoryId,categoryName,categoryTypeId,company,newEntriesByCategories);
              result.then( (categorie) =>{
              console.log(categorie);
              console.log(categorie["@id"]);
              let category_id=categorie["@id"];
             // setCategoryId(category_id);
              //categoryModal.onClose();
             //  updateGrid(categoryId,categoryName);
               //  categoryEditModal.onClose();
              });


        if(checkedItems.length<1) {
          // let row= [{category:categoryName,category_id:categoryId,type:type,type_id:categoryType,poste:'',id:''}];
          // gridApi.applyTransaction({add:row});
        }
        else{
          //  gridApi.forEachNodeAfterFilterAndSort(applyCategory);
        }
  }

 if (!rowData) {
     return null;
  }
  else  {
    return (
      <div>
       
          {/* <ModalW></ModalW> */}
          {/* {checkedItems} */}
          {/* {expenseToCategorize } */}
          <CategoryModal
            categoryType={categoryType}
            categoryNameChange={categoryNameChange}
            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            selectedCategory={""}
            onCategorySend={sendCategory}
     
            expenseToCategorize={expenseToCategorize}
            revenueToCategorize={revenueToCategorize}
            isOpen={categoryModal.isOpen}
            addCategory={addCategory}
            onClose={categoryModal.onClose}
          />

          {/* {expenseToCategorize } */}
          <CategoryEditModal
            categoryName={categoryName}
            categoryType={categoryType}
            categoryId={categoryId}
            categoryNameChange={categoryNameChange}
            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            selectedCategory={""}
            onCategorySend={sendCategory}
            //entriesToTranfertChecked
            
            entriesToTransfertChecked={entriesToTransfertChecked}
            entriesByCategories={entriesByCategories}
            categoryToTransfert={categoryToTransfert}
            company={company}
            isOpen={categoryEditModal.isOpen}
            addCategory={addCategory}
            onClose={categoryEditModal.onClose}
            onDeletedCategory={onDeletedCategory}
            onTransfertCategory={onTransfertCategory}
          />

          <CategoryNewModal
            categoryName={categoryName}
            categoryType={categoryType}
            categoryId={categoryId}
            onPostCategory={onPostCategory}
            company={company}
            isOpen={categoryNewModal.isOpen}
            addCategory={addCategory}
            onClose={categoryNewModal.onClose}

          />

          <EntryModal
            params={params}
            categoryType={categoryType}
            categoryName={categoryName}
            selectedCategory={selectedCategory}

            expenseCategories={expenseCategories}
            revenueCategories={revenueCategories}
            bankAccount={bankAccount}
            onChange={categoryNameChange}
            reportId={reportId}
            putEntry={putEntry}
            addCategory={addCategory}
            monthsColums={monthsColums}
            fixedAmount={fixedAmount}

           // startDate={startDate}
            startDateFormated={startDateFormated}
           // finishDate={finishDate}
            finishDateFormated={finishDateFormated}
            vatSelected={vatSelected}
            recurring={recurring}
            recurringInterval={recurringInterval}
            isOpen={entryModal.isOpen}
            onClose={entryModal.onClose}
          />


      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(6, auto)",
          lg: "repeat(6, auto)",
        }}
        templateRows={{ md: "repeat(1, auto)", lg: "repeat(1, auto)" }}
        gap="40px"
      >
    

          <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    
                  </Text>
                  <Link onClick={goForecast} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Revenir à mon Prévisionnel
                  </Text>
                  </Link>
                </Flex>
 
              </Flex>
            </CardBody>
          </Card>
{/*
                    <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Décaissements
                  </Text>
                  <Link onClick={newExpense}>
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Créer
                  </Text>
                  </Link>
                  
                   <Link onClick={categorizeExpense} >
                  <Text fontSize="sm" color="#fff" fontWeight="normal">
                    ({expenseToCategorize.length}) à Catégoriser 
                  </Text>
                  </Link>

                  <Link onClick={categorizeExpense} >
                        <Text fontSize="sm" color="#fff" fontWeight="normal">
                    Gérer les catégories
                  </Text>
                  </Link>
                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                as={FaSignOutAlt}
                h={"20px"}
                w={"20px"}
                      color={iconTeal}
              />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>

                    <Card bg="teal.300">
            <CardBody>
              <Flex justify="space-between" w="100%" align="center">
                <Flex direction="column">
                  <Text fontSize="lg" color="#fff" fontWeight="bold" mb="2px">
                    Solde actuel
                  </Text>

                  <Text fontSize="lg" color="#fff" fontWeight="bold">
                    {bankBalance} € 
                  </Text>
                  <br/>
                  <Text fontSize="xs" color="#fff" fontWeight="normal" mb="2px">
                   mise à jour le {bankBalanceDate}
                  </Text>
                </Flex>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconBoxInside}>
                      <Icon
                    as={FaUniversity}
                    h={"24px"}
                    w={"24px"}
                    color={iconTeal}
                  />
                </IconBox>
              </Flex>
            </CardBody>
            </Card>

 */}
</Grid>
              <Link 
                style={{ marginBottom: "5px", fontWeight: "bold" }}
              >
                
                </Link>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify="space-between"
            align="center"
            w="100%"
            mb="12px"
          >
          

          <div
              className="ag-theme-alpine"
              style={{ minHeight: "75vh", minWidth: "100%", marginTop: 30 }}
            >

              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                onCellValueChanged={onCellValueChanged}
                gridOptions={gridOptions}
                columnDefs={columns}
                onGridReady={onGridReady}
                onRowDataUpdated={pinnedRow()}
                allowContextMenuWithControlKey={true}
                getContextMenuItems={getContextMenuItems}
                columnTypes={columnTypes}
                //onRowDragMove={onRowDragMove}
                onRowDragEnd={onRowDragEnd}
                // pinnedBottomRowData= []
                //   pinnedBottomRowData={ pinnedRow(1, 'Bottom')}
              >
                {/* {columns.map(column => (
              <AgGridColumn {...column} key={column.field} />
            ))} */}
              </AgGridReact>

              {/* <AgGridReact
               rowData={rowData2}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions2}
                           >
          	{columns2.map(column => (
            <AgGridColumn {...column} key={column.field} />
          ))}                        
          </AgGridReact> */}
              </div>
          </Flex>
  
   
      </div>
    );
}

}

export default Categories;
