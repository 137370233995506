import React, { useEffect, useState } from 'react';

import useCategory from './useCategory';

import {

    Input,
    FormLabel,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
    extendTheme,
  Stack,
} from "@chakra-ui/react";




export  function VariableAmount(props) {
  const [months, setMonths] = useState(null);

useEffect( ()=>{

   monthToShow=[];
      //  console.log( "month "+params.data.months);
       // setStartDate(params.data.next_date);
        Object.entries(this.props.months).forEach(([key, value]) => {
            //console.log(key+' '+ value);
       monthToShow.push(     
    
            <FormControl>
                   <Stack direction="row" spacing="24px">
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px" minW="100px">
                    {key}
                    </FormLabel>
                    <Input placeholder=""  defaultValue={value} />
                    </Stack>
                    </FormControl>
                   
                );

         });

     setMonths(monthToShow);
    });

  return (
      {months}
  );
}