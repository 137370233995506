
import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import { SelectModel} from './SelectModel';

import cashFlowApi from "utils/cashFlowApi.js";
import vatsFromFile from 'utils/vats.json';
import VatCol from './VatCol';
import { 
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
        Switch, 
    FormLabel,
  FormControl
} from '@chakra-ui/react';



function VatModal(props) {

const [scrollBehavior, setScrollBehavior] = React.useState('inside');
const { isOpen , onOpen, onClose } = useDisclosure();

 //const [checkedItems,setCheckedItems] = useState([]);
// const [options, setOptions] = useState();
// 

const [vatSelected, setVatselected] = useState();


const categorySend =()=>{
  props.onCategorySend(newCategoryName);
}
  const vatChange = (value,id) => {
      /// console.log("variableAmountChange avant" + id +' :'+value.checked);

      localStorage.setItem(id, value.checked);
      //localStorage.setItem("refreshToken", data.data.refresh_token);
    // setShowVariableAmount(value.checked);
    // setShowFixedAmount(!value.checked);
  
  //  console.log("variableAmountChange après" + showVariableAmount);
//    console.log(props.params);
  //  console.log(props.params.data);
    //console.log(props.params.data.poste);
  };


const spanSwitch=(e)=> {
// console.log(e);
//   let element = document.getElementById(e.id);
//     let txt = element.innerText;

//   element.innerHTML = `<input onblur='spanReset(this)' value='${txt}' />`;
//   document.getElementsByTagName('input')[0].focus();
}

const spanReset=(e)=>  {
  let txt = e.value;
  let element = document.getElementById('element');

  element.innerHTML = `<span onclick='spanSwitch(this)'> ${txt} </span>`;
}

const handleChange=(e)=> {
    //const value = e.target.value.split("_");
    const value=e.target.value;
    const isChecked = e.target.checked;

  props.onEntriesCategorized(value,isChecked);
  };

  let [vats, setVats] = useState([]);

  // useEffect(() => {
  //   let isSubscribed = true;

  //   if (vats.length == 0) {
  //       Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {
  //       //  console.log(value["@id"] + ' '+ localStorage.getItem(value["@id"]) );
  //         let checked= localStorage.getItem(value["@id"]);
  //         if(checked===null)checked=false;
  //         else if(checked==='false')checked=false;
  //       vats.push({ value: value["@id"], label: value.rate ,category:value.category,country:value.country,checked:checked});
  //     });
  //   } 
  // }, [vats]);

  useEffect(() => {
//console.log(vats);
    if (props.isOpen) {

    let isThereChecked=[];
    Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {
            let checked= localStorage.getItem(value["@id"]);
            if(checked===null || checked==='false')checked=false;
            else if(checked==='true')isThereChecked.push({value: value["@id"]});       
      });
    console.log(isThereChecked);
    console.log(isThereChecked.length);
    if(isThereChecked.length<1)  {
      console.log("skdfjksjf");
      localStorage.setItem('/api/vats/1', true);

    }   
    let newVats=[];

      Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {

        let checked= localStorage.getItem(value["@id"]);
        console.log(checked+' '+value["@id"]);
        if(checked===null)checked=false;
        else if(checked==='false')checked=false;
        //else if(checked==='true')isThereChecked.push(value: value["@id"]);
        newVats.push({ value: value["@id"], label: value.rate ,category:value.category,country:value.country,checked:checked});
 
      });
     setVats(newVats);
  }
  }, [props.isOpen]);

const onVatChange=(e)=> {
  console.log(e);
    //const value = e.target.value.split("_");
    if(e !==null){
   const value=e.value;
   setVatselected(value);
   }
   // const isChecked = e.target.checked;

  //props.onEntriesCategorized(value,isChecked);
  };  
let vatSchemes=[];

vatSchemes.push({ value:'/api/vat_schemes/1', label: 'Régime Normal' });
vatSchemes.push({ value:'/api/vat_schemes/2', label: 'Régime simplifié' });
vatSchemes.push({ value:'/api/vat_schemes/3', label: 'Franchise de base' });
// if (typeof props.params.data !== "undefined")
//   selectedCategory = {
//     value: "/api/forecast_categories/" + props.params.data.category_id,
//     label: props.params.data.category,
//   };


const vatSend=()=>{

if(vatSelected!==null)
   props.onVatSend(vatSelected);
 // let   report=cashFlowApi.putReport(props.reportId,vatSelected,null);
             // let report = cashFlowApi.getReport(reportUuid); 
          //let report = reports["hydra:member"][0];
         // updateData(report,params);
          //.then(testPinned  );
          
    //report.then( (report) => updateData(report,params)   );
  onClose();
}

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',

    minHeigh:'500px',
    transform: 'translate(-50%, -50%)',

  },
};


let categories = [...new Set(vats.map(vat => {// console.log(vat.category); 
  return vat.category;
}
  ))];


// console.log(categories);


//  console.log("select " + props.selected);
    let previousCategory ="";
return (
  <Modal
    onClose={props.onClose}
    isOpen={props.isOpen}
    isCentered
    size="xl"
    scrollBehavior={scrollBehavior}
    style={customStyles}

  >
    <ModalOverlay />
    <ModalContent minHeight="200px">
      <ModalHeader  >Gestion de la TVA</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
  <Stack direction="column" spacing="24px">
        <Stack direction="row" spacing="24px">
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="bold" mb="10px" color="teal">
                  Régime de TVA
                </FormLabel>
                <SelectModel
                  options={vatSchemes}
                  selected={props.selected}
                  onChange={onVatChange}
                />
              </FormControl>
        </Stack>


        <Stack direction="row" spacing="24px" >
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="bold" mb="10px" color="teal">
                  Taux de TVA utilisés
                </FormLabel>
   
              </FormControl>
        </Stack>

 <Stack
              direction="row"
              spacing="10px"
              w="100%"
            >

  {
    categories.map((category)=>{
   //  console.log(category)
     let rows='';
 

     if(category!=""){
     rows=[];
      for (const vat of vats) {
         //  console.log(vat.category + "" + category)    
                if(vat.category==category ) { 
                 //console.log(vat.checked)
                 rows.push(   <Stack
                          direction="row"
                          spacing="20px"
                          w="100%"
                        >

                          <Switch
                            colorScheme="teal"
                            defaultChecked={vat.checked}
                            onChange={(e) => vatChange(e.target,vat.value)}
                          />
                        <FormControl alignItems="center">
                          <FormLabel fontSize="sm"  mb="10px">
                            {vat.label}
                          </FormLabel>

                        </FormControl>

                          </Stack>
                          )
                       
                        }
                }
      
           }

 return ( <Stack
            direction="column"
            spacing="20px"
            w="33%"
          > <Stack
                          direction="row"
                          spacing="20px"
                          w="100%"
                        >

                          <Switch
                            colorScheme="teal"
                            defaultChecked='true'
                            onChange={(e) => vatChange(e.target,category)}
                          />
                        <FormControl alignItems="center">
                          <FormLabel fontSize="sm"  mb="10px" >
                            {category}
                          </FormLabel>

                        </FormControl>

                          </Stack>
          {rows} </Stack>)

    })




  }
     
     </Stack>


</Stack>
      </ModalBody>
      <ModalFooter>
       

              <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
                         onClick={vatSend}
            >
              Enregistrer
            </Button>

        {/* <Button onClick={onClose}>Close</Button> */}
      </ModalFooter>
    </ModalContent>
  </Modal>
);

}

export default VatModal;