import React, {useEffect, useState , useRef }from "react";

import {  Calendar,
  CalendarDefaultTheme,
  CalendarControls,
  CalendarPrevButton,
  CalendarNextButton,
  CalendarMonths,
  CalendarMonth,
  CalendarMonthName,
  CalendarWeek,
  CalendarDays,
} from '@uselessdev/datepicker';

// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent ,
  PopoverBody 
} from "@chakra-ui/react";


import { ChakraProvider } from '@chakra-ui/react';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import fr from 'date-fns/locale/fr';

export function StartDate(props){
  const calendarRef = useRef(null);
  const [date, setDate] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
//console.log(format(props.value, 'yyyy-MM-dd').toString());
//console.log("props.value : "+ props.value);
let dateInit=null;
// if(props.value!= null){
//   let x =props.value.substring(0, 10).split("-");
//   dateInit = x[2]+'/'+ x[1]+'/'+ x[0];
// }

  const [value, setValue] = useState(dateInit);


  const initialRef = useRef(null);

    const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
      setValue(target.value);
      var dateValue = target.value.split("/");
      console.log(target.value);

      console.log(target.value.length);
      console.log(dateValue[2]+'-'+dateValue[1]+"-"+dateValue[0]);

        //var dateValue = new Date(target.value);
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();

    // let todayFomated = yyyy+'-' + mm + '-' + dd ;
    if(target.value.length==10){
          console.log(target.value);
          let newDate= new Date(dateValue[2]+'-'+dateValue[1]+"-"+dateValue[0]);
          console.log(newDate);
          setDate(newDate);
          props.onChange(dateValue[2]+'-'+dateValue[1]+"-"+dateValue[0]);
       }

    // if (match(target.value)) {
    //   onClose();
    // }
  };

  const handleSelectDate = (date: CalendarDate) => {
    setDate(date);
    setValue(() => ( format(date, 'dd/MM/yyyy') : ''));
    props.onChange(format(date, 'yyyy-MM-dd').toString());
    onClose();
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });


   useEffect(() => {
    if(props.isOpen && props.value !== undefined){
      console.log("bouj  "+props.value);
      let newDate = new Date( props.value);
      setDate(newDate);
      setValue(() => ( format(newDate, 'dd/MM/yyyy') : ''));
    //   

    }

  }, [props.isOpen]);

  return (

      <ChakraProvider theme={CalendarDefaultTheme}>
      
      <Box>
      <Popover
        placement="bottom-end"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isLazy
      >
        <PopoverTrigger>
          <Box onClick={onOpen} ref={initialRef}>
            <Input
              placeholder="jj/mm/aaaa"
              value={value}
              onChange={handleInputChange}
              htmlSize={9}
              width="auto"
              fontSize="xs"
            />
          </Box>
        </PopoverTrigger>

        <PopoverContent
          p={0}
          w="min-content"
          border="none"
          outline="none"
          _focus={{ boxShadow: 'none' }}
          ref={calendarRef}
        >
          <Calendar
            value={{ start: date }}
             locale={fr}
            onSelectDate={handleSelectDate}
            singleDateSelection
          >
            <PopoverBody p={0}>
              <CalendarControls>
                <CalendarPrevButton />
                <CalendarNextButton />
              </CalendarControls>

              <CalendarMonths>
                <CalendarMonth>
                  <CalendarMonthName />
                  <CalendarWeek />
                  <CalendarDays />
                </CalendarMonth>
              </CalendarMonths>
            </PopoverBody>
          </Calendar>
        </PopoverContent>
      </Popover>
    </Box>
    </ChakraProvider>
  )

  }