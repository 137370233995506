
// Chakra imports
import {
  Button,
  Flex,
    Input,
    FormLabel,
      Icon,
        Text,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
    ICellRendererParams,
} from "@chakra-ui/react";


import React from "react";
import { useEffect, useState, useRef } from 'react';
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import paymentApi from "utils/paymentApi.js";

import "utils/style.css";

import intervals from 'utils/intervals.json';


import { StartDate } from "./StartDate";
import { Category } from "./Category";

import SourceRenderer from "./SourceRenderer.js";


// import intervals from 'utils/intervals.json';


// import { StartDate } from "./components/StartDate";
// import { Category } from "./components/Category";

// import SourceRenderer from "./components/SourceRenderer.js";

// function onCellValueChanged(params){
//   setRowChange(params)
//   console.log(params);
// }
import {
    useDisclosure,
  Modal,
  Switch,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';


export default (props) => {
   const { isOpen, onOpen, onClose } = useDisclosure();

  const [amount, setAmount] = useState([]);
  const [price, setPrice] = useState([]);
  const [prices, setPrices] = useState([]);
  const [name, setName] = useState([]);
  const index="";
  const [selectedPeriodicity, setSelectedPeriodicity] = useState([]);
 const [periodicityOptions, setPeriodicityOptions] = useState([]);

  
   const callPrice=()=>{
    console.log("esdfdf");
     //let company="/api/companies/af0c93df-1634-461e-9194-11ee68e4975e";
    let  result = paymentApi.updatePrice(amount,props.price["@id"],selectedPeriodicity);
  }

  
   useEffect(() => {
       console.log("tototototo" +  props.periodicities);

const options=[];
  
    if(props.price.periodicity!== undefined){
      console.log("couc");
            Object.entries( props.periodicities).forEach(([key, value]) => {
                //console.log("tototototo"+key+value);
                let  selected=0;
                //if(value.id==periodicitySelected)selected=1;
                //selected='+options.selected+' selected={selected} 
                options.push(<option value={value["@id"]} >{value.name}</option>)

          }      );
          
       setPeriodicityOptions(options);

     // console.log("amount"+props.price.periodicity["@id"]);
      setSelectedPeriodicity( props.price.periodicity["@id"]);
    }
         // console.log("periodicity"+props.price.periodicity["@id"]);



  // setName(props.product.label);
  // setAmount(props.product.mean);
  
  // setPrices(props.product.prices);

  // setPeriodicitySelected(props.product.interval);
  // setStartDate(params.data.next_date);


  //setPrice(params.data.prices[0].amount);
  //setOperationType("put");
  // setStart(props.product.next_date);

    }   ,[props.price.periodicity]);
   useEffect(() => {
    

        


  // setName(props.product.label);
  // setAmount(props.product.mean);
  
  // setPrices(props.product.prices);

  // setPeriodicitySelected(props.product.interval);
  // setStartDate(params.data.next_date);


  //setPrice(params.data.prices[0].amount);
  //setOperationType("put");
  // setStart(props.product.next_date);
  },[props.price.periodicity]);
  //  });
const handleAmountChange=(e)=>{

     console.log(e.target.value);
    setAmount(e.target.value);
}
// handlePasswordChange: function(e) {
//    this.setState({password: e.target.value});
// },
  const [columnsPrices, setColumnsPrices] = useState([]);
        let colPrice=[];

        colPrice.push({headerName: 'Prix', field: 'amount', sortable: true ,  width: 120}); 
        colPrice.push({headerName: 'Périodicité', field: 'periodicity.name', sortable: true ,  width: 120}); 

        colPrice.push({headerName: 'Actif', field: 'active', sortable: true ,  width: 100}); 
        // colPrice.push({headerName: 'Actif', field: 'startDate', sortable: true ,  width: 60}); 
        // colPrice.push({headerName: 'Actif', field: 'endDate', sortable: true ,  width: 60}); 
        colPrice.push({headerName: 'Stripe', field: 'stripeId', sortable: true ,  width: 140, valueFormatter: sourceFormatter
        ,  cellRenderer: SourceRenderer
        }); 

      ///  setColumnsPrices(colPrice);




function sourceFormatter(params){
  if(params.value=='') return "0"; 
  return "1";
}

const [category,setCategory]=useState(1);

const onCategoryChange= (value)=>{
   //console.log("bam jjj" + value);
   if(value!= null) {
    //console.log(Object.entries(value)[0][1]);
    setCategory(Object.entries(value)[0][1]);
   } 
}
const [recurring,setRecurring]=useState(true);

const switchChange= (value)=>{
   //console.log("bam" + value.checked);
  setRecurring(value);
}

const [start,setStart]=useState('');

const startOnChange=(value)=>{
    console.log("date"+value);
   // setName(value);
  setStart(value);
}

const [finish,setFinish]=useState('');

const finishOnChange=(value)=>{
    console.log("date"+value);
   // setName(value);
  setFinish(value);
}

const MySelect = props => (
<Select
    //{...props}

    // onChange = {value => props.input.onChange(value)}
    // onBlur={() => props.input.onBlur(props.input.value)}//If needed
    defaultValue={selectedPeriodicity}
    // options=  {periodicityOptions}
    // placeholder={props.placeholder}
  > {periodicityOptions}</Select>


            //  <Select fontSize="xs" placeholder="" defaultValue={props.price.periodicity} color="gray.400" >
            //           {periodicityOptions}
            //         </Select>
);

const   onCellValueChanged= (params)=>{
       // setRowChange(arr => [...arr, params]);
        //console.log(params);
        // isOpen=true;

    }

  //   const gridPricesOptions = {
  //    domLayout: 'autoHeight',
  //    rowClassRules: {

  //       'bouh': function(params) {
  //         let response =false;
  //         if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
  //         return response;},
                  
    
  //       'rag-red-outer': function(params) { return params.data.year === 2000; }
  //   },
  //     onCellDoubleClicked: (params) =>  {
  //       // console.log(params);
  //       // setName(params.data.name);
  //       // setAmount(params.data.mean);
  //       // setPrices(params.data.prices[0].amount);

  //       // setPeriodicitySelected(params.data.interval);
  //       //  onOpen();
  //       //  setStart(params.data.next_date);
  
  //     }
  // }
return (
  
        <Modal  size="xl" onClose={props.onPriceModalClose} isOpen={props.isPriceModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Produit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
                <Stack direction="row" spacing="24px">
                  <FormControl>
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Montant
                    </FormLabel>
                    <Input placeholder="" fontSize="xs"  defaultValue={props.price.amount} onChange={handleAmountChange}/>
                  </FormControl>
                </Stack>              
              
                {/* <Stack direction="row" spacing="24px">
             <FormControl>
                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Categorie
                    </FormLabel>
              </FormControl>

                 </Stack> */}

                  <Stack direction="row" spacing="24px">
                        {/* {rows } */}
                <FormControl>
                 <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Recurrent
                    </FormLabel>
                    <Switch colorScheme="cyan" defaultIsChecked onChange={(e)=>switchChange(e.target)} />
                </FormControl>
                  <FormControl>
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Périodicité
                    </FormLabel>
                    <MySelect/>
                  </FormControl>
                  </Stack>
                <Stack direction="row" spacing="24px">
                  <FormControl>
                    
                    {/* <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Début
                    </FormLabel>
                    <StartDate label="Début" onChange={startOnChange}  value={start}></StartDate>
                  
                   <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Fin
                    </FormLabel>
                   <StartDate label="Fin" onChange={finishOnChange} value={start}></StartDate> */}
              </FormControl>
          </Stack>

<Stack>

  
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >

    <div className="ag-theme-alpine" style={{maxHeight:"75vh", width: "80vw",top:400}}>

           {/* <AgGridReact
               rowData={props.product.prices}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridPricesOptions}
                           >
                {colPrice.map(column => (
                  <AgGridColumn {...column} key={column.field} />
                ))}

           </AgGridReact> */}
       </div>
      </Flex>

</Stack>

          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Close</Button>  */}
             <Button onClick={() =>callPrice() } variant="transparent-with-icon" color="teal.300" >Enregistrer  </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      )
}

