import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import paymentApi from "utils/paymentApi.js";


export default (props: ICellRendererParams) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  //console.log(props.@id);
  const buttonClicked = () => {
    if(cellValue==1)   {
      alert(`${cellValue} déjà actif`);
     
    } 
    else {
      alert(`${cellValue} activer? ${props.data["@id"]}`);
       paymentApi.activePriceStripe(props.data["@id"]);
    }
  };

  return (
    <span>
      <span>{cellValue}</span>&nbsp;
      <button onClick={() => buttonClicked()}>Push For[" To"]tal</button>
    </span>
  );
};