/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";

import { useEffect, useState, useRef } from 'react';

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable2 from "components/Tables/SearchTable2";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { columnsData2 } from "variables/columnsData";
import tableData2 from "variables/tableData2.json";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import cashFlowApi from "utils/cashFlowApi.js";

import paymentApi from "utils/paymentApi.js";

import "utils/style.css";
import { useHistory } from "react-router-dom";

import ModalW from './modal.js';
// function onCellValueChanged(params){
//   setRowChange(params)
//   console.log(params);
// }
import {
    useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';





function OrderList(props) {
 const { isOpen, onOpen, onClose } = useDisclosure()
    const   onCellValueChanged= (params)=>{
           setRowChange(arr => [...arr, params]);
            //console.log(params);
           // isOpen=true;
   
       }



function currencyFormatter(params) {
  return '£' + formatNumber(params.value);
}
  const [columns, setColumns] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [columns2, setColumns2] = useState([]);
  const [rowData2, setRowData2] = useState([]);

let history = useHistory();
  const gridOptions = {
      domLayout: 'autoHeight',
     rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  {
        console.log(params);
               onOpen();
           //isOpen();
      }
  }

    const gridOptions2 = {
        domLayout: 'autoHeight',
      rowClassRules: {
        // apply green to 2008

        //'bouh': function(params) { return params.node.rowIndex=== 0; },

                'bouh': function(params) {
                  let response =false;
                  if(params.data.poste === 'Total revenus' || (params.data.poste === 'Total dépenses'))response=true;
                  return response;},
                  
                //  return ;
              //  },
              //  'bouh': function(params) { return params.data.poste === 'Total dépenses';},
        // apply amber 2004
       // 'rag-amber-outer': function(params) { return params.data.poste === 'Total revenus'; },

        // apply red to 2000
        'rag-red-outer': function(params) { return params.data.year === 2000; }
    },
      onCellDoubleClicked: (params) =>  console.log(params)
  }
    


  const monthsToShow=12;

   useEffect(() => {
     let isSubscribed = true;
         if (isSubscribed) {

        //cashFlowApi.getAccount('15121')
        let reports = cashFlowApi.getReports();   
        let products = paymentApi.getProducts();   
      
        reports.then((reports)=>{
                    let uuid=reports["hydra:member"][0]["@id"].split("/");
          console.log("reports" +uuid[3]);

                  //let uuid='d12bf430-ff9a-4c35-9b3e-5e687807753d'
           let report = cashFlowApi.getReport(uuid[3]); 
           //146331     
            //let report = cashFlowApi.getAccount(14631);  
        report.then( (report) =>{
  //

   // history.push("/authentication/sign-up");

          let col=[];
          let colNum=0;
          //setColumns([report.periodColumn]);
          col.push({headerName: 'Poste', field: 'poste', sortable: true ,  width: 180}); 

          Object.entries(report.period).forEach(([key, value]) => {

            if(colNum<monthsToShow)col.push({headerName: key, field: key.replace('-', ''), sortable: true ,    width: 100,  valueFormatter: 'value.toLocaleString("fr-FR", {minimumFractionDigits: 0})',type: 'rightAligned' }); 
            colNum++;
          });

          setColumns(col);
          setColumns2(col);
          let row=[];

          let row_col=[];
          let row_col2=[];
          let datas=report.test;
          //let datas=report.forecast;

          Object.entries(datas[0]).forEach(([key, value]) => {
            row_col.push( value);
           // row_col2.push( value);
             // console.log("$1" + key);
             // row_col.push(value);
            Object.entries(value).forEach(([key, value]) => {
                //setRowData(value);
                  
                 // console.log("$2" + key +" : "+ value);
              //  Object.entries(value).forEach(([key, value]) => {
          
                
              //  });
              //row_col[key]=value;
             //row_col.push({key: value});
            });
          //  row.push(row_col);
            // console.log("items :" +items);
            // for (var i = 0; i < items.length; i++) {
            // console.log("iii" + key + ':' +  items[i][1].amount);
               // row.push({key: value);
           // }
           console.log("bouh " +row_col);
       // setRowData(row_col);
        });

          Object.entries(datas[1]).forEach(([key, value]) => {
            row_col.push( value);  
        });

          Object.entries(datas[2]).forEach(([key, value]) => {
            row_col.push( value);
            row_col2.push( value);
        });


        // end total
          Object.entries(datas[3]).forEach(([key, value]) => {
            row_col.push( value);
            row_col2.push( value);
        });

          setRowData(row_col);
          setRowData2(row_col2);
        });
        });





           //Array.prototype.forEach.call(rowData, ()=>{console.log("logn" + element)});
          // let data = rowData;
          // data.foreach(element => console.log("logn" + element) );


           // setRowData(rowData.period);

           //}
             
          //)
      }
    return () => isSubscribed = false;
   }, [props.id]);


 if (!rowData) {
     return null;
  }
  else  {
    return (
<div>



    <Flex direction="column" pt={{ sm: "125px", md: "75px" }}>
        {/* <ModalW></ModalW> */}

              <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
       
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
        mb="24px"
      >
    <div className="ag-theme-alpine" style={{maxHeight:"75vh", width: "80vw",top:400}}>
           <AgGridReact
               rowData={rowData}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions}
                           >
            {columns.map(column => (
              <AgGridColumn {...column} key={column.field} />
            ))}

           </AgGridReact>



           {/* <AgGridReact
               rowData={rowData2}
               onCellValueChanged={onCellValueChanged}
               gridOptions={gridOptions2}
                           >
          	{columns2.map(column => (
            <AgGridColumn {...column} key={column.field} />
          ))}                        
          </AgGridReact> */}

       </div>


      </Flex>

    </Flex>
    </div>
  );
}

}

export default OrderList;
