
import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import { Category } from "./Category";
import { 
     Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';



function CategoryNewModal(props) {

const [scrollBehavior, setScrollBehavior] = React.useState('inside');
const { isOpen , onOpen, onClose } = useDisclosure();
const [newCategoryName, setNewCategoryName] = useState(null);


const [deleteDisabled, setDeleteDisabled] = useState(false);

const categoryNameChange= (e)=>{
  console.log(e.target.value);
  setNewCategoryName(e.target.value);
}

const postCategory =()=>{
  props.onPostCategory(newCategoryName);
}

const [category, setCategory] = useState(1);

const [categories, setCategories] = useState([]);

  useEffect(() => {
//console.log(vats);
    if (props.isOpen) {

        // if(props.categoryType==1)setCategories(props.expenseCategories);
        // else setCategories(props.revenueCategories);
          setNewCategoryName(props.categoryName);

      }
  }, [props.isOpen]);


 const onCategoryAdd = (value) => {
  console.log(value.value+'Boom ' + value.label);
  props.addCategory(value.value,value.label,props.categoryType);
//   setCategory(value.value);
//   props.categoryNameChange(value.label);
 };


return (
  <Modal
    onClose={props.onClose}
    isOpen={props.isOpen}
    isCentered
    size="xl"
    scrollBehavior={scrollBehavior}
  >
    <ModalOverlay />
    <ModalContent  style={{minHeight:'400px'}}>
      <ModalHeader>
      Ajouter une catégorie
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
       
      
      <Stack direction="row" spacing="24px">
          <FormControl>
            <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                Nom
            </FormLabel>
            <Input fontSize="xs"  placeholder="" defaultValue={props.categoryName}  onChange={(e)=>categoryNameChange(e)}   />
 </FormControl>
        </Stack>
  
      </ModalBody>

      <ModalFooter>
              <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
             
             onClick={postCategory}
            >
              Créer
            </Button>
        </ModalFooter>

    </ModalContent>
  </Modal>
);

}

export default CategoryNewModal;