import React, { useEffect, useState } from 'react';
import Select from 'react-select';

// Chakra imports
import {

    Input,
    FormLabel,
  FormControl,

  Stack,
} from "@chakra-ui/react";

export  function MonthValue(props) {
  const [monthChange, setMonthChange] = useState(null);
//console.log("choum"+props.value);
  const monthOnChange=(event)=>{
    console.log("truc "+event.target.value);
      props.onChange(event.target.value);
}

useEffect( ()=>{

     // props.onChange(monthChange);
    });
// fontSize="xs" 
  return (
            <FormControl>
                   <Stack direction="row" spacing="4px">
                    <FormLabel  fontSize="xs"  fontWeight="bold" mb="10px" minW="70px">
                    {props.label}
                    </FormLabel>
                    <Input fontSize="xs" key={props.label}   placeholder=""  defaultValue={props.defaultValue} onChange={monthOnChange}/>
                    </Stack>
                    </FormControl>
  );
}