/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { useCallback, useEffect, useState, useRef,useMemo } from 'react';

import ReactDOM from "react-dom";
import { HashRouter, Route, BrowserRouter,Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
//      <Route path={`/`} component={AdminLayout} />
require('dotenv').config();

//console.log("api "+process.env.REACT_APP_API_KEY);
//const { env } = require('process');

const NODE_ENV = process.env.NODE_ENV;
//env.NODE_ENV 
console.log(NODE_ENV);

//require('dotenv').config();

// useEffect(() => {
//     window.process = {
//       ...window.process,
//     };
//   }, []);
  


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={`/authentication`} component={AuthLayout} />
      <Route path={`/`} component={AdminLayout} />
      <Route path={`/rtl`} component={RTLLayout} />

    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
