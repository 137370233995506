import sfcApi from "./sfcApi.js";


function getProducts(id) {
  let url = "/products";
  //if(id!=="undefined") url+="/"+id;
  //console.log("tout");

  let products = sfcApi.getApi(url, true);
  products
    .then((data) => {
      // console.log("toto 2" + data);
      localStorage.setItem("products", JSON.stringify(data["hydra:member"]));
    })
    .catch((err) => console.log(err));

  return products;
}

async function getReport(id) {
  const url = "/reports/"+id;
  //console.log("tout");

  let report =  await sfcApi.getApi(url, true);
 
 // console.log("bouh  : report " +report);

  if(report=== undefined){
      console.log("go  : report " +report);
      let report =  await sfcApi.getApi(url, true);
  }
  console.log("bouh  : fin " +report);

  return report;
}

async function getReports() {
  const url = "/reports?properties[]=name";
  let reports =  await sfcApi.getApi(url, true);
 
  if(reports=== undefined){
      //console.log("go  : report " +reports);
      let reports =  await sfcApi.getApi(url, true);
  }
  //console.log("bouh  : fin " +reports);

  return reports;
}

async function getCategories() {
  const url = "/forecast_categories";
  console.log("tout");

  let categories =  await sfcApi.getApi(url, true);
 
  //console.log("bouh  : report " +report);

  // if(report=== undefined){
  //     console.log("go  : report " +report);
  //     let report =  await sfcApi.getApi(url, true);
  // }
  //console.log("bouh  : fin " +report);


// console.log(report.period["2022-04"][0]["amount"]);
// Object.entries(report.period).forEach(([key, value]) => {
//     console.log("huhu" + key );
// });
//   console.log("000"+report.period["2022-04"].length);

  return categories;
}

async function getPeriodicities() {
  const url = "/periodicities";

  let periodicities =  await sfcApi.getApi(url, true,"GET");
 
  //console.log("000"+report.period["2022-04"].length);

  return periodicities;
}


function postEntry(id,amount,name,category,recurring,startDate,finishDate) {
  const URL = api_route + "/forecast_entries";
  // let yourConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //     "Content-Type": "multipart/form-data"
  //   },
  // };
  var body = {
    
  report:"api/reports/d12bf430-ff9a-4c35-9b3e-5e687807753d",
  reccuring: recurring,
  startDate: startDate,
  finishDate: finishDate,
  amount: amount,
  name: name,
  category: category,
	type:"api/forecast_types/2"

};

  const dataPromise = axios({
    method: "post",
    url: URL,
    data: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": " application/json",
    },
  })
    .then(function (response) {
      //handle success
      console.log(response);
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });

  // const promise = axios.post(yourURL, yourConfig);
  // .get(
  //   "http://127.0.0.1/7fallen/public/index.php/api/cards.jsonld?" + query
  // )
  // using .then, create a new promise which extracts the data
  // const dataPromise = promise
  //   .then((response) => response.data)
  //   .catch((err) => {
  //     console.log(err);
  //     return null;
  //   });

  return dataPromise;
}

function activePriceStripe(price) {
  const url = "/price_stripes";

  var body = { price: price};

  result=sfcApi.callApi(url, body,'post');
  return result;
}

function addPrice(price) {
  const url = "/prices";

  var body = { price: price};

  let result=sfcApi.callApi(url, body,'post');
  return result;
}

function updatePrice(amount,price,periodicity) {

  price= price+ '';
 let  priceId= price.split("/");
 console.log(priceId[3]);
  const url = "/prices/"+priceId[3];

  var body = { 
    amount:amount,
    periodicity:periodicity};

  let result=sfcApi.callApi(url, body,'put');
  return result;
}
function addProduct(name,company,type) {
  const url = "/products";

  var body = { company: company, name:name};
  //console.log(body);
  let result=sfcApi.callApi(url, body,type);
  return result;
}

export default {
  updatePrice,
  addProduct,
  getProducts,
  getPeriodicities,
  getReport,
  postEntry,
  getCategories,
  getReports,
  activePriceStripe
};
