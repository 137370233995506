
import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import { Category } from "./Category";
import { 
     Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';



function CategoryEditModal(props) {

const [scrollBehavior, setScrollBehavior] = React.useState('inside');
const { isOpen , onOpen, onClose } = useDisclosure();
const [newCategoryName, setNewCategoryName] = useState(null);
 //const [checkedItems,setCheckedItems] = useState([]);
// const [options, setOptions] = useState();


const [deleteDisabled, setDeleteDisabled] = useState(false);

const categoryNameChange= (e)=>{
  //console.log("name" + e.target.value);
  //props.categoryName=e.target.value;()
  console.log(e.target.value);
  setNewCategoryName(e.target.value);
  // console.log(selectedCategory);
  // // resetMonths();
  // if (value != null) {
  //   //console.log(Object.entries(value)[0][1]);
     // setCategory(value.value);
}

const categorySend =()=>{
  props.onCategorySend(newCategoryName);
}

const spanSwitch=(e)=> {
// console.log(e);
//   let element = document.getElementById(e.id);
//     let txt = element.innerText;

//   element.innerHTML = `<input onblur='spanReset(this)' value='${txt}' />`;
//   document.getElementsByTagName('input')[0].focus();
}

const spanReset=(e)=>  {
  let txt = e.value;
  let element = document.getElementById('element');

  element.innerHTML = `<span onclick='spanSwitch(this)'> ${txt} </span>`;
}



const [category, setCategory] = useState(1);

//
//let selectedCategory;

// if (typeof props.params.data !== "undefined")
//   selectedCategory = {
//     value: "/api/forecast_categories/" + props.params.data.category_id,
//     label: props.params.data.category,
//   };




const [entries, setEntries] = useState([]);
const [categories, setCategories] = useState([]);
  let options=null;
function search(nameKey, myArray){
    for (let i=0; i < myArray.length; i++) {
      myArray[i].disabled=false;
        if (myArray[i].id === nameKey) {
          myArray[i].disabled=true;
           // return myArray[i];
        }
    }
    return myArray;
}
    if (props.isOpen) {

   if(props.categoryType=='/api/forecast_types/1') {
  //  expenseVisible=1;
   // revenueVisible=0;
//    let op=props.expenseCategories;
// //   const index =  props.expenseCategories.indexOf(2);
//     const row = op.findIndex(row => row.includes(props.categoryId));
//     console.log(row);
//     op[row]["disabled"]=true;
//     console.log(op);
//     options = op;
     options = search(props.categoryId, props.expenseCategories);
    //setOptions();
   }
   else{

       // let op=props.revenueCategories;
       // const resultObject = search(props.categoryId, op);
       options = search(props.categoryId, props.revenueCategories);
          // console.log(resultObject);
          //  console.log(op +' ' + props.categoryId);
          //  const row='';
//   const index =  props.expenseCategories.indexOf(2);
//const row = op.findIndex(row => row.includes(props.categoryId));
// console.log(row);
//     op[row]["disabled"]=true;
//     console.log(op);
  
  //  options = props.revenueCategories;
  //  setOptions();
   }
      }

  useEffect(() => {

    if (props.isOpen) {
      // console.log(props);
      //   console.log(props.categoryId);
      //   console.log(props.entriesByCategories);

      setSelectedTransfertCategory(null);

        enableTransfertButton();
        if(props.categoryType=='/api/forecast_types/1')setCategories(props.expenseCategories);
        else setCategories(props.revenueCategories);
          setNewCategoryName(props.categoryName);
        //  console.log(props.entriesByCategories[props.categoryId]);
          if(props.entriesByCategories[props.categoryId]===undefined)props.entriesByCategories[props.categoryId]=[];
        setEntries(props.entriesByCategories[props.categoryId]);
      //if(props.entriesByCategories[props.categoryId].length>0)setDeleteDisabled(true);
      }
  }, [props.isOpen]);

  useEffect(() => {
       if (props.isOpen) {
        console.log(entries);
        if(entries.length>0)setDeleteDisabled(true);
        else setDeleteDisabled(false);
        console.log(entries.length);
      }
  }, [entries]);

const [checkedItems,setCheckedItems] = useState([]);
const [selectedTransfertCategory,setSelectedTransfertCategory]=useState();

  useEffect(() => {
    console.log(checkedItems.length);
    if (props.isOpen) {
          enableTransfertButton();
        //console.log(checkedItems.length);
      }
  }, [checkedItems]);

function onSelectedCategoryToTransfert(value) {
  console.log(value);
  // setCategory(value.value);
   if(value!==null) setSelectedTransfertCategory(value.value);
   else setSelectedTransfertCategory(value);
  props.categoryToTransfert(value);
  enableTransfertButton();
};

  useEffect(() => {
    if (props.isOpen) {
         console.log("tyu"+selectedTransfertCategory);
          enableTransfertButton();
      }
  }, [selectedTransfertCategory]);

const [transfertDisabled, setTransfertDisabled] = useState(false);

function enableTransfertButton(){
  //console.log(selectedTransfertCategory);
  if(checkedItems.length>0 && selectedTransfertCategory!==null)setTransfertDisabled(false);
  else setTransfertDisabled(true);
}

const onEntriesToTransfertChecked=(e)=> {
    //const value = e.target.value.split("_");
    const value=e.target.value;
    const isChecked = e.target.checked;
    console.log(value,isChecked);


    let previousItems=[...checkedItems];

    if(isChecked==false){
      var index = previousItems.indexOf(value);
      if (index >= 0) {
        previousItems.splice( index, 1 );
        setCheckedItems( previousItems);
      }
    }
    if(isChecked==true) {
        var index = previousItems.indexOf(value);
        console.log(index);
        if (index <0)  setCheckedItems([...checkedItems, value ]);
      }

    props.entriesToTransfertChecked(value,isChecked);
  };


  useEffect(() => {
//console.log(vats);
    if (props.isOpen) {
    console.log(categories);
}
  }, [categories]);
 

 const onCategoryAdd = (value) => {
  console.log(value.value+'Boom ' + value.label);
  props.addCategory(value.value,value.label,props.categoryType);
//   setCategory(value.value);
//   props.categoryNameChange(value.label);
 };




const onDeletedCategory = () => {
 // console.log(value);
  // setCategory(value.value);
  props.onDeletedCategory();
};

const onTransfertCategory = () => {
//  console.log(value);
  // setCategory(value.value);
  props.onTransfertCategory();
  setEntries(props.entriesByCategories[props.categoryId]);
};

function CheckBoxEntry(props){
        //console.log(props["name"] +""+props["id"]);
        return  <Checkbox value={props["id"]} onChange={onEntriesToTransfertChecked} size='sm' fontSize="xs">{props["name"]}</Checkbox>
      
}

// function YesShowTransfert(props) {
//   return
// }

// function NoShowTransfert(props) {
//   return <> </>;
// }

// function ShowTransfert (props) {
 
//   if (deleteDisabled) {
//     return <YesShowTransfert />;
//   }
//   return <NoShowTransfert />;
// }

return (
  <Modal
    onClose={props.onClose}
    isOpen={props.isOpen}
    isCentered
    size="xl"
    scrollBehavior={scrollBehavior}
  >
    <ModalOverlay />
    <ModalContent  style={{minHeight:'400px'}}>
      <ModalHeader>{props.categoryName}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack direction="row" spacing="24px">
          <FormControl>
            {/*
            <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                      Nom
            </FormLabel>
            <Input fontSize="xs"  placeholder="" defaultValue={props.categoryName}  onChange={(e)=>categoryNameChange(e)}   />
           */}
          {/*
            // <Category
            //   options={options}
            //   selectedCategory={props.selectedCategory}
            //   onCategoryChange={onCategoryChange}
            //   categoryType={props.categoryType}
            //   addCategory={props.addCategory}
            // ></Category>
            // */}
          </FormControl>
        </Stack>

 <Stack style={{ display: deleteDisabled ? "" : "none" }} >
      <Stack direction="row" spacing="24px">
          <FormControl>
            {/*

                   <FormLabel fontSize="xs"  fontWeight="bold" mb="10px">
                      Nom
                    </FormLabel>
                    <Input fontSize="xs"  placeholder="" defaultValue={props.categoryName}  onChange={(e)=>categoryNameChange(e)}   />
           */}
  
            <FormLabel fontSize="sm" fontWeight="bold" mb="10px">
              Transfert de poste vers une autre catégorie
            </FormLabel>
          
            <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
              Catégorie de destination
            </FormLabel>

            <Category
              options={options}
              selectedCategory={props.selectedCategory}

              onCategoryChange={onSelectedCategoryToTransfert}
              categoryType={props.categoryType}
              onCategoryAdd={onCategoryAdd}
              company={props.company}
            />
          </FormControl>
        </Stack>


             <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
             Postes à transférer
            </FormLabel>

        <Stack direction="row" spacing="24px" style={{minHeight:'200px'}}>
          {/* <CheckboxGroup colorScheme="teal" defaultValue={[]} size='md' > */}
            <Stack
              spacing={[1, 5]}
              direction="column"
            >
              {entries.map((value, index) => {
                console.log(value);
console.log(value["name"]);
console.log(value["@id"]);
               // if (index % 2 == 0)
                  //if (index < props.expenseToCategorize.length / 2)
return <Checkbox value={value["@id"]}onChange={onEntriesToTransfertChecked} size='sm' fontSize="xs">{value["name"]}</Checkbox>
      
                 {/*return    <CheckBoxEntry name={value["name"]} id={value["@id"]} />*/}
              })}

            </Stack>
          {/* </CheckboxGroup> */}
        </Stack>
        </Stack>
        {/* {props.expenseToCategorize.length} */}

                  {/*<Stack
              spacing={[1, 5]}
              direction="column"
             
            >
              {props.expenseCategories.map((categorie) => (
            

<div id={categorie["value"]+'/content'} >
  <span 
      onClick={() => {
        spanSwitch(categorie["value"]);
      }}

   id={categorie["value"]}>   {categorie["label"]} </span>
</div>
              ))}
            </Stack>
*/}


      </ModalBody>


        <ModalFooter>
          {/* <Button onClick={onClose}>Close</Button> */}
      {/*
             <Button
              type="submit"
              bg="red.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "red.200",
              }}
              _active={{
                bg: "red.400",
              }}
              onClick={onDeletedCategory}
              disabled={deleteDisabled}
            >
              Supprimer
            </Button>
*/}
              <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
             onClick={onTransfertCategory}
             disabled={transfertDisabled}
             /*  onClick={categorySend}*/
            >
              Transférer
            </Button>
        </ModalFooter>

    </ModalContent>
  </Modal>
);

}

export default CategoryEditModal;