import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';



export  function SelectModel(props) {
  const [selectedOption, setSelectedOption] = useState(null);

const onChange =(e)=>{
    console.log(e);
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' , fontSize: '0.75rem'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma("#00B8D9");
    const fontSize = 'xs';
    return {
      ...styles,
       fontSize: '0.75rem',
      // backgroundColor: isDisabled
      //   ? undefined
      //   : isSelected
      //   ?"#00B8D9"
      //   : isFocused
      //   ? color.alpha(0.1).css()
      //   : undefined,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : "#00B8D9",
      // cursor: isDisabled ? 'not-allowed' : 'default',

      // ':active': {
      //   ...styles[':active'],
      //   backgroundColor: !isDisabled
      //     ? isSelected
      //       ? "#00B8D9"
      //       : color.alpha(0.3).css()
      //     : undefined,
      // },
    };
  },
  // input: (styles) => ({ ...styles, ...dot() }),
  // placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot("#00B8D9") }),
};



useEffect( ()=>{

    if(selectedOption!=null){
      console.log(Object.entries(selectedOption)[0][1]);
    }
  props.onChange(selectedOption);
});
console.log(props.options);
console.log(props.selected);
  return (
    <div className="App">
      <Select
        defaultValue={props.selected}
        onChange={setSelectedOption}
        options={props.options}
        styles={colourStyles}
      />
    </div>
  );
}