
import React, { useEffect, useState } from 'react';
import Select , { StylesConfig }  from 'react-select';
import chroma from 'chroma-js';

import { Checkbox, CheckboxGroup } from '@chakra-ui/react';

import { SelectModel} from './SelectModel';

import cashFlowApi from "utils/cashFlowApi.js";

import { 
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
      Input,
    FormLabel,
  FormControl
} from '@chakra-ui/react';

import { Vat } from "./Vat";
import vatsFromFile from 'utils/vats.json';

function DragnDropVatModal(props) {


  let [vats, setVats] = useState([]);
  
  // useEffect(() => {
  //   console.log("props.id"+props.id);
  //   let isSubscribed = true;
  //   console.log("lol vat");

  //   if (vats.length == 0) {
  //     Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {

  //       let checked= localStorage.getItem(value["@id"]);
  //       if(checked===null)checked=false;
  //       if(checked)vats.push({ value: value["@id"], label: value.rate });
  //     });
  //   } else redirect();
  // }, [vats]);



  useEffect(() => {
        if (props.isOpen) {
let newVats=[];
      Object.entries(vatsFromFile["hydra:member"]).forEach(([key, value]) => {

        let checked= localStorage.getItem(value["@id"]);
        //console.log(checked);
        if(checked===null)checked=false;
        if(checked==='true')newVats.push({ value: value["@id"], label: value.rate });
      });
      if(newVats.length<1)    newVats.push({ value: 'A', label: '20.00',category:'Métropole',country:'France',checked:'true'});
 
    setVats(newVats);
    }

  }, [props.isOpen]);


const [scrollBehavior, setScrollBehavior] = React.useState('inside');
const { isOpen , onOpen, onClose } = useDisclosure();


const vatSend =(vatId,vatRate)=>{
  if(vatId!==undefined &&  props.entryId!==undefined){
      //console.log(vatId,props.entryId);

  props.onVatSelected(vatId,props.entryId,vatRate);
  }

}


const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    minHeigh:'500px',
    transform: 'translate(-50%, -50%)',
  },
};


return (
  <Modal
    onClose={props.onClose}
    isOpen={props.isOpen}
    isCentered
    size="xl"
    scrollBehavior={scrollBehavior}
    style={customStyles}
  >
    <ModalOverlay />
    <ModalContent minHeight="200px">
      <ModalHeader>Sélectionnez un taux de TVA</ModalHeader>
      <ModalCloseButton />
      <ModalBody>


        <Stack direction="column" spacing="24px">
    {
            vats.map(vat=>{ return(           
             <Stack direction="row" spacing="44px">      
             <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
          
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
                onClick={() => vatSend(vat.value,vat.label)}
            >
              {vat.label}
            </Button>
            </Stack>
            )})
           }

        </Stack>



      </ModalBody>
      <ModalFooter>
        <Button
   
          variant="transparent-with-icon"
          color="teal.300"
        >
          Enregistrer{" "}
        </Button>

     
      </ModalFooter>
    </ModalContent>
  </Modal>
);

}

export default DragnDropVatModal;