import axios from "axios";
import jwt_decode from "jwt-decode";
import sfc from "./sfcApi.js";

//const api_url = "https://dev.api.cashforecast.smartflowconnect.com";

//http://treso.localhost
let api_url = "";
 if(process.env.REACT_APP_CASH_API_URL !== undefined)api_url= process.env.REACT_APP_CASH_API_URL;

let current_url = location.hostname;
//console.log(current_url);
let env_mode=current_url.split(".");
console.log(env_mode[0]);
// if(env_mode[0]=='dev' )api_url='https://dev.'+api_url;
// else if(env_mode[0]!='localhost') api_url='https://'+api_url;

//api_url = "https://dev.api.cashforecast.smartflowconnect.com";

//api_url = "https://dev.api.cashforecast.smartflowconnect.com";


// console.log("api "+process.env.REACT_APP_CASH_API_URL);
// 
//let api_url = "https://dev.api.cashforecast.smartflowconnect.com";
const api_route = api_url + "/api";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function isLogged() {
  let token = localStorage.getItem("token");
  console.log("token" + token);
  if (token) return true;
  return false;
}


async function getJwt(){
  
let jwt = localStorage.getItem("token");

const start = Math.round(Date.now() / 1000);

console.log("tutu 1: " +start);

let decoded=null;
  try {
      decoded = jwt_decode(jwt)
    // valid token format
  } catch(error) {
   console.log("bouh" + error)
  }


 if(decoded!==null) {
   
console.log("tutu 2: " +decoded.exp);
 let i =decoded.exp-start;
 console.log("tutu 3: " + i);


 if(i<120) {
 let result= await  sfc.refreshToken();
  result.then((response) => {console.log(response)});

  // refreshToken();
    //await sleep(4000);
  jwt = localStorage.getItem("token");

  console.log("tutu 4: bim" +jwt );

    }
 }
return jwt;
}

async function getApi(url, token) {
  const yourURL = api_route + url + ".jsonld";
  console.log(yourURL);
  let yourConfig = "";

 let jwt = localStorage.getItem("token");

const start = Math.round(Date.now() / 1000);

console.log("tutu 1: " +start);

let decoded=null;
  try {
      decoded = jwt_decode(jwt)
    // valid token format
  } catch(error) {
   console.log("bouh" + error)
  }


 if(decoded!==null) {
   
  console.log("tutu 2: " +decoded.exp);
 let i =decoded.exp-start;
 console.log("tutu 3: " + i);


 if(i<120) {
    console.log("boom");
    let result=  sfc.refreshToken();
     await sleep(4000);
     jwt = localStorage.getItem("token");
   console.log(result);
  
    // result.then((response) => {
    //   console.log(response)
    //   jwt = localStorage.getItem("token");
    //   console.log("tutu 4: bim" +jwt );
    // })
    // .catch((err) => {
    //           //  
    //   console.log(err.response);
    //   // if(err.response.status=='401'){
    //   //   console.log("no refresh");
    //   //   return '401';
    //   // }

    //   });
 // refreshToken();
//  sleep(2000);


  }
 }

  if (token)
    yourConfig = {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    };

    axios.interceptors.response.use(response => {
    console.log("catch :" +response);
   return response;
}, error => {
  console.log(error);
  if (error.response.status === 401) {
    console.log("bouh : refresh" );
          // sfc.refreshToken()
          // .then((response) => {
          //   console.log(response);
          //   if (response==''){
          //     console.log(response);
          //     return error;
          //   }
          //   //else getApi(url, token).then((response)=>{   console.log("bouh : response" + response );
          //  // return  response; });

          // })
          // .catch((err) => {
          //   console.log(err.response.data);
          //   return err.response.data;
          // })
  }
  return error;
});

console.log("tchoum");
try {
const { data } = await axios.get(yourURL, yourConfig);
//if(data==undefined)
console.log(data);
  return data;
}

catch (error) {
   console.log(error);
  if (error.response) {
    // Request made but the server responded with an error
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // Request made but no response is received from the server.
    console.log(error.request);
  } else {
    // Error occured while setting up the request
    console.log('Error', error.message);
  }
}

// data.catch((err) => {
//   console.log("catch :" +err.response.data.message);
//       if (err.response.data.message == "Expired JWT Token") {
//         // refreshToken()
//         //   .then((response) => {
//         //     return getApi(url, token);
//         //   })
//         //   .catch((err) => {
//         //     console.log(err.response.data);
//         //     return err.response.data;
//         //   })
     //   }}  );
      
//console.log("code : " + data.code);
//if(data.code)
  // .get(
  //   "http://127.0.0.1/7fallen/public/index.php/api/cards.jsonld?" + query
  // )
  // using .then, create a new promise which extracts the data
 // const dataPromise = promise;
    // .then((response) => {response.data ;
    // response.data.period.forEach(period => console.log("pp :" +period));})
    // .catch((err) => {
    //   if (err.response.data.message == "Expired JWT Token") {
    //     refreshToken()
    //       .then((response) => {
    //         return getApi(url, token);
    //       })
    //       .catch((err) => {
    //         console.log(err.response.data);
    //         return err.response.data;
    //       });
    //   } else {
    //     console.log(err.response.data);
    //     return err.response.data;
    //   }
    // });


}

async function callApi(url, body,method) {
  //var body = { card: "/api/cards/" + id };
  let jwt= await getJwt();
  let application='application/json';
  if(method=="PATCH")application='application/merge-patch+json';
  const dataPromise = axios({
    method: method,
    url: api_route + url,
    data: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": application,
    },
  })
    .then(function (response) {
      //handle success
      console.log(response);
    return response.data;
    })
    .catch(function (response) {
      //handle error
      console.log(response);
      return response;
    });

  return dataPromise;
}

async function postReport(reportId,vatScheme,name){
  let url='/reports/'+reportId;

 
  let body = {
    vatScheme:vatScheme,
    ...(name !==null && {name: name}),
  };

  let report=  await callApi(url, body,'POST');
  
  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
return report;
}

async function putReport(reportId,vatScheme,name){
  let url='/reports/'+reportId;

 
  let body = {
    vatScheme:vatScheme,
    ...(name !==null && {name: name}),
  };

  let report=  await callApi(url, body,'PATCH');
  
  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
return report;
}

async function putEntryVat(entryId,vat){
  let url='/forecast_entries/'+entryId;

 
  let body = {
    vat:vat,
   // ...(name !==null && {name: name}),
  };

  let result=  await callApi(url, body,'PATCH');
  
  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
return result;
}


async function putEntryCategory(entryId,category){
  let url='/forecast_entries/'+entryId;

 
  let body = {
    category: category,
  //  ...(name !==null && {name: name}),
  };

  let result=  await callApi(url, body,'PATCH');
  
  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
return result;
}

async function postCategory(name,type,company,entries){
  let url='/forecast_categories';

 //console.log(company);

let body ='';
if(Array.isArray(entries)){
if (entries.length>0){
    body = {
    name: name,
  type: type,
  company:company,
      entry: entries,
  };

}

}
else {
    body = {
    name: name,
  type: type,
  company:company

  };
}

  let category=  await callApi(url, body,'POST');
  
  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
return category;
}

async function deleteCategory(id) {

console.log(id);
  let url = "/forecast_categories/"+id;



  let category = await callApi(url, '', "DELETE");


  return category;
}

async function putCategory(id,name, type,company, entries) {
  console.log(entries);
  let categoryId=id.split("/");
  if(entries===null)entries=[];
  if (categoryId.length > 1) id = categoryId[3];

  let url = "/forecast_categories/"+id;
let body = '';
if (entries.length==0){
    body = {
    name: name,
    //type: type,
  };
}
else {
    body = {
    name: name,
  //  type: type,
    entry: entries,
  };
}

  let category = await callApi(url, body, "PATCH");

  //  category
  //   .then((data) => {
  //    console.log("toto 2" + data);
  //     return data;
  //   })
  //   .catch((err) => console.log(err));
  return category;
}


function getAccount(id) {
  const url = "/accounts_infos/"+id;
  //console.log("tout");

  let accounts = getApi(url, true);
  accounts
    .then((data) => {
      // console.log("toto 2" + data);
      localStorage.setItem("datas", JSON.stringify(data["hydra:member"]));
    })
    .catch((err) => console.log(err));

  return accounts;
}


function getUser(id) {
  const url = "/users/"+id;
  //console.log("tout");

  let user = getApi(url, true);
  user
    .then((data) => {
      // console.log("toto 2" + data);
      //localStorage.setItem("datas", JSON.stringify(data["hydra:member"]));
    })
    .catch((err) => console.log(err));

  return user;
}

async function getReport(id) {
  const url = "/reports/"+id;
  //console.log("tout");

  let report =  await getApi(url, true);
 
 // console.log("bouh  : report " +report);

  if(report=== undefined){
      console.log("go  : report " +report);
      let report =  await getApi(url, true);
  }
  console.log("bouh  : fin " +report);

  return report;
}

async function getCompany(id) {
  const url = "/companies/"+id;
  //console.log("tout");

  let company =  await getApi(url, true);
 
 // console.log("bouh  : report " +report);

  if(company=== undefined){
      console.log("go  : companies " +company);
      let company =  await getApi(url, true);
  }
  console.log("bouh  : fin " +company);

  return company;
}

async function getCompanies() {
  const url = "/companies";
  //console.log("tout");

  let companies =  await getApi(url, true);
 
 // console.log("bouh  : report " +report);

  if(companies=== undefined){
      console.log("go  : companies " +companies);
      //let companies =  await getApi(url, true);
  }
  console.log("bouh  : fin " +companies);

  return companies;
}
async function getReports() {
  //?properties[]=name
  const url = "/reports";
  let reports =  await getApi(url, true);
 
  if(reports=== undefined){
      //console.log("go  : report " +reports);
      let reports =  await getApi(url, true);
  }
  //console.log("bouh  : fin " +reports);

  return reports;
}

async function getCategories() {
  const url = "/forecast_categories";
  console.log("tout");

  let categories =  await getApi(url, true);
 
  //console.log("bouh  : report " +report);

  // if(report=== undefined){
  //     console.log("go  : report " +report);
  //     let report =  await getApi(url, true);
  // }
  //console.log("bouh  : fin " +report);


// console.log(report.period["2022-04"][0]["amount"]);
// Object.entries(report.period).forEach(([key, value]) => {
//     console.log("huhu" + key );
// });
//   console.log("000"+report.period["2022-04"].length);

  return categories;
}



 async function  postEntry(report,type,amount,name,category,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankAccount,vatSelected) {
  const url = "/forecast_entries";
  // let yourConfig = {
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //     "Content-Type": "multipart/form-data"
  //   },
  // };

  if(finishDate=="")finishDate=null;
   let mappedEntry=null;
if (bankAccount !== null && label !=="") {

  // mappedEntry = {
  //   label: label,
  //   bankAccount: "/api/accounts_infos/" + bankAccount,
  // };
  // 
}
if(amount=="")amount="0";
  var body = {
    
  report:report,
  recurring: recurring,
  startDate: startDate,
  finishDate: finishDate,
  amount: amount,
  name: name,
  category: category,
  recurringInterval:periodicity,
	type:type,
  entryPeriods: entryPeriods,
      vat:vatSelected
 // ,mappedEntry: mappedEntry

};


console.log(body);
 const dataPromise = await callApi(url,body,'POST');

  // const dataPromise =  await axios({
  //   method: "post",
  //   url: URL,
  //   data: JSON.stringify(body),
  //   headers: {
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //     "Content-Type": " application/json",
  //   },
  // })
  //   .then(function (response) {
  //     //handle success
  //     console.log(response);
  //     return response;
  //   })
  //   .catch(function (response) {
  //     //handle error
  //     console.log(response);
  //   });



  return dataPromise;
}


async function putEntry(id,type_id,amount,name,category_id,periodicity,recurring,startDate,finishDate,entryPeriods,label,bankId,vatSelected,disabled) {
  const URL = api_route + "/forecast_entries/"+id;
  console.log(entryPeriods);

  if(finishDate=="")finishDate=null;
   if(startDate=="")startDate=null;
      let entryPeriodId='';
      let periodId='';
      let entryPeriodAmount='';
      let monthPeriod='';
      let periods=[];
  
      // for (let  p in entryPeriods) {

      //   periodId=entryPeriods[p]["id"];
      //   entryPeriodAmount=entryPeriods[p]["amount"].toString();
      //   monthPeriod=entryPeriods[p]["period"];

      // if(periodId!=undefined && entryPeriodAmount !="0" ){
      //     periods.push({
      //         id:periodId,
      //         amount:entryPeriodAmount,
      //         period:monthPeriod,
      //         recurringInterval:"/api/intervals/9"
      //     });
      //   }
      //   else if(!isNaN(entryPeriodAmount)  && entryPeriodAmount !="0" ){
      //     periods.push({
      //         amount:entryPeriodAmount,
      //         period:monthPeriod,
      //         recurringInterval:"/api/intervals/9"
      //     });
      //   }
      // }
   
  var body = {
    "@id":"/api/forecast_entries/"+id,
    id:id,
    recurring: recurring,
    recurringInterval: periodicity,
    startDate: startDate,
    finishDate: finishDate,
    amount: amount,
    name: name,
    category: category_id,
    type: type_id,
    vat:vatSelected,
    disabled:disabled,
    //category: "api/forecast_categories/"+category_id,

    // type:"api/forecast_types/"+type_id,
    entryPeriods: entryPeriods,
    //   mappedEntry:{
    //     label:label,
    //     bankAccount:"/api/accounts_infos/"+bankAccount
    // }
  };
 // if(periodicity!='')body.push( {});
  let bankAccount="/api/accounts_infos/"+bankAccount;

  //   if(label!="")body.push(    mappedEntry:{
  //     label:label,
  //     bankAccount:bankAccount;
  // }) ;
console.log(body);

  const dataPromise = axios({
    method: "PUT",
    url: URL,
    data: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": " application/json",
    },
  })
    .then(function (response) {
      //handle success
      console.log(response);
            return response.data;
    })
    .catch(function (response) {
      //handle error
      console.log(response);
    });

  return dataPromise;
}

async function excludeForecast(label){
  var url = "/mapped_entries";
  var body = {
	label:label,
  addedDate: "2022-03-24T11:12:52.227Z",

	bankAccount:"/api/accounts_infos/14631",
	forecastExcluded:true
  };
  return await callApi(url,body,'post');

}
export default {
  isLogged,
  getAccount,
  getReport,
  postEntry,
  getCategories,
  getReports,
  putEntry,
  excludeForecast,
  postCategory,
  putCategory,
  getCompanies,
  getCompany,
  getUser,
  putReport,
  postReport,
  putEntryVat,
  putEntryCategory,
  deleteCategory
};
